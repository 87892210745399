import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.userToken;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        return headers;
      }
    },
  }),

  tagTypes: ["Assets", "Transactions"],
  endpoints: (build) => ({
    getCustomerAssets: build.query({
      query: (data) => {
        const { id } = data;
        return {
          url: `user/assets/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Assets"],
    }),

    getCustomerAssetTransactions: build.query({
      query: (data) => {
        const { id } = data;

        return {
          url: `user/asset/transactions/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Assets", "Transactions"],
    }),

    getCustomerAssetTransactionFiles: build.query({
      query: (data) => {
        const { id } = data;
        return {
          url: `user/asset/transaction/files/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Assets", "Transactions"],
    }),

    getCustomerAssetTransactionFile: build.query({
      query: (data) => {
        const { id } = data;
        return {
          url: `user/asset/transaction/file/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Assets", "Transactions"],
    }),
  }),
});

export const {
  useLazyGetCustomerAssetsQuery,
  useLazyGetCustomerAssetTransactionsQuery,
  useLazyGetCustomerAssetTransactionFilesQuery,
  useLazyGetCustomerAssetTransactionFileQuery,
} = userApi;
