import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const api = createApi({
  reducerPath: "adminApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.userToken;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        return headers;
      }
    },
  }),

  tagTypes: [
    "Customers",
    "Administrators",
    "Assets",
    "User",
    "Products",
    "Transactions",
    "Geography",
    "Sales",
    "Performance",
    "Dashboard",
    "AdminDashboard",
    "AssetDocuments",
    "Quotations",
    "Workflows",
    "Training",
    "AccountDocuments",
    "ClientDocuments",
    "Settings",
    "NotificationSetting",
    "Backup",
  ],
  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => `general/user/${id}`,
      providesTags: ["User"],
    }),
    getProducts: build.query({
      query: () => "client/products",
      providesTags: ["Products"],
    }),
    // getTransactions: build.query({
    //   query: ({ page, pageSize, sort, search }) => ({
    //     url: "client/transactions",
    //     method: "GET",
    //     params: { page, pageSize, sort, search },
    //   }),
    //   providesTags: ["Transactions"],
    // }),
    getGeography: build.query({
      query: () => "client/geography",
      providesTags: ["Geography"],
    }),
    getSales: build.query({
      query: () => "sales/sales",
      providesTags: ["Sales"],
    }),
    getAdmins: build.query({
      query: () => "management/admins",
      providesTags: ["Admins"],
    }),
    getUserPerformance: build.query({
      query: (id) => `management/performance/${id}`,
      providesTags: ["Performance"],
    }),
    getDashboard: build.query({
      query: () => "general/dashboard",
      providesTags: ["Dashboard"],
    }),
    getTransactionAmount: build.query({
      query: () => "general/transactionamount",
      providesTags: ["Dashboard"],
    }),
    getAdministrators: build.query({
      query: () => "admin/administrators",
      providesTags: ["Administrators"],
    }),
    addAdministrator: build.mutation({
      query(data) {
        const { ...body } = data;
        return {
          url: "admin/administrators",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Administrators"],
    }),
    resetAdministratorPassword: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin/administrators/password/${id}`,
          method: "PATCH",
          body: body,
        };
      },
      invalidatesTags: ["Administrators"],
    }),
    deleteAdministrator: build.mutation({
      query(data) {
        const { id } = data;
        return {
          url: `admin/administrators/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Administrators", "Training"],
    }),
    updateAdministrator: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin/administrators/${id}`,
          method: "PATCH",
          body: body,
        };
      },
      invalidatesTags: ["Administrators"],
    }),
    getAdminDashboard: build.query({
      query: () => "admin/dashboard",
      providesTags: ["AdminDashboard", "Assets"],
    }),
    getCustomers: build.query({
      query: () => "admin/customers",
      providesTags: ["Customers"],
    }),
    addCustomer: build.mutation({
      query(data) {
        const { ...body } = data;
        return {
          url: "admin/customers",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Customers"],
    }),
    resetCustomerPassword: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin/customers/password/${id}`,
          method: "PATCH",
          body: body,
        };
      },
      invalidatesTags: ["Customers"],
    }),
    deleteCustomer: build.mutation({
      query(data) {
        const { id } = data;
        return {
          url: `admin/customers/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [
        "Customers",
        "Assets",
        "Transactions",
        "AssetDocuments",
      ],
    }),
    updateCustomer: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin/customers/${id}`,
          method: "PATCH",
          body: body,
        };
      },
      invalidatesTags: ["Customers"],
    }),
    getCustomerAssetsFromAdmin: build.query({
      query: (data) => {
        const { id, ...body } = data;
        return {
          url: `admin/assets/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Assets"],
    }),
    updateCustomerAssetFromAdmin: build.mutation({
      query(data) {
        const { id, ...body } = data;

        return {
          url: `admin/assets/${id}`,
          method: "PATCH",
          body: body,
        };
      },
      invalidatesTags: ["Assets"],
    }),
    addCustomerAssetFromAdmin: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin/assets/${id}`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Assets", "Customers"], // Why Customers? as we change the assetCount
    }),
    deleteCustomerAssetFromAdmin: build.mutation({
      query(data) {
        const { id } = data;
        return {
          url: `admin/assets/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: [
        "Assets",
        "Customers",
        "Transactions",
        "AssetDocuments",
      ],
    }),
    getAllAssetsFromAdmin: build.query({
      query: () => {
        return {
          url: `admin/assets`,
          method: "GET",
        };
      },
      providesTags: ["Assets"],
    }),
    getAssetTransactionsFromAdmin: build.query({
      query: (data) => {
        const { id, ...body } = data;
        return {
          url: `admin/assets/transaction/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Transactions"],
    }),
    updateAssetTransactionFromAdmin: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin/assets/transaction/${id}`,
          method: "PATCH",
          body: body,
        };
      },
      invalidatesTags: ["Transactions"],
    }),
    addAssetTransactionFromAdmin: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin/assets/transaction/${id}`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Transactions"],
    }),
    deleteAssetTransactionFromAdmin: build.mutation({
      query(data) {
        const { id } = data;
        return {
          url: `admin/assets/transaction/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Transactions"],
    }),
    uploadAssetTransactionFilesFromAdmin: build.mutation({
      query(param) {
        const { id, data } = param;
        return {
          url: `admin/assets/transaction/files/${id}`,
          method: "POST",
          body: data,
          formData: true,
        };
      },
      invalidatesTags: ["Transactions"],
    }),
    getAssetTransactionFilesFromAdmin: build.query({
      query: (data) => {
        const { id } = data;
        return {
          url: `admin/assets/transaction/files/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Transactions"],
    }),
    deleteAssetTransactionFilesFromAdmin: build.mutation({
      query(param) {
        const { id, ...body } = param;

        return {
          url: `admin/assets/transaction/files/${id}`,
          method: "DELETE",
          body: body,
        };
      },
      invalidatesTags: ["Transactions"],
    }),
    getAssetDocumentsFromAdmin: build.query({
      query: (data) => {
        const { id, ...body } = data;
        return {
          url: `admin/assets/document/${id}`,
          method: "GET",
        };
      },
      providesTags: ["AssetDocuments", "Assets"],
    }),
    updateAssetDocumentFromAdmin: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin/assets/document/${id}`,
          method: "PATCH",
          body: body,
        };
      },
      invalidatesTags: ["AssetDocuments", "Assets"],
    }),
    addAssetDocumentFromAdmin: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin/assets/document/${id}`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["AssetDocuments", "Assets"],
    }),
    deleteAssetDocumentFromAdmin: build.mutation({
      query(data) {
        const { id } = data;
        return {
          url: `admin/assets/document/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["AssetDocuments", "Assets"],
    }),
    getAssetDocumentFilesFromAdmin: build.query({
      query: (data) => {
        const { id } = data;
        return {
          url: `admin/assets/document/files/${id}`,
          method: "GET",
        };
      },
      providesTags: ["AssetDocuments", "Assets"],
    }),
    uploadAssetDocumentFilesFromAdmin: build.mutation({
      query(param) {
        const { id, data } = param;
        return {
          url: `admin/assets/document/files/${id}`,
          method: "POST",
          body: data,
          formData: true,
        };
      },
      invalidatesTags: ["AssetDocuments", "Assets"],
    }),
    deleteAssetDocumentFilesFromAdmin: build.mutation({
      query(param) {
        const { id, ...body } = param;

        return {
          url: `admin/assets/document/files/${id}`,
          method: "DELETE",
          body: body,
        };
      },
      invalidatesTags: ["AssetDocuments", "Assets"],
    }),
    getQuotations: build.query({
      query: () => "admin/quotations",
      providesTags: ["Quotations"],
    }),
    updateQuotation: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin/quotations/${id}`,
          method: "PATCH",
          body: body,
        };
      },
      invalidatesTags: ["Quotations"],
    }),
    addQuotation: build.mutation({
      query(data) {
        const { ...body } = data;
        return {
          url: `admin/quotations`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Quotations"],
    }),
    deleteQuotation: build.mutation({
      query(data) {
        const { id } = data;
        return {
          url: `admin/quotations/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Quotations"],
    }),
    getQuotationFiles: build.query({
      query: (data) => {
        const { id } = data;
        return {
          url: `admin/quotations/files/${id}`,
          method: "GET",
        };
      },
      providesTags: ["Quotations"],
    }),
    uploadQuotationFiles: build.mutation({
      query(param) {
        const { id, data } = param;
        return {
          url: `admin/quotations/files/${id}`,
          method: "POST",
          body: data,
          formData: true,
        };
      },
      invalidatesTags: ["Quotations"],
    }),
    deleteQuotationFiles: build.mutation({
      query(param) {
        const { id, ...body } = param;
        return {
          url: `admin/quotations/files/${id}`,
          method: "DELETE",
          body: body,
        };
      },
      invalidatesTags: ["Quotations"],
    }),
    // client, without login
    getQuotation: build.query({
      query(param) {
        const { path } = param;
        return {
          url: `client/quotation/${path}`,
          method: "GET",
        };
      },
      providesTags: ["Quotations"],
    }),
    getWorkflows: build.query({
      query: () => "admin/workflows",
      providesTags: ["Workflows"],
    }),
    updateWorkflow: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin/workflows/${id}`,
          method: "PATCH",
          body: body,
        };
      },
      invalidatesTags: ["Workflows"],
    }),
    addWorkflow: build.mutation({
      query(data) {
        const { ...body } = data;
        return {
          url: `admin/workflows`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Workflows"],
    }),
    deleteWorkflow: build.mutation({
      query(data) {
        const { id } = data;
        return {
          url: `admin/workflows/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Workflows"],
    }),
    // admin, settings
    getSettings: build.query({
      query: () => "admin/settings",
      providesTags: ["Settings"],
    }),
    updateSetting: build.mutation({
      query(data) {
        return {
          url: `admin/settings`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["Settings"],
    }),

    // admin, notification setting
    getNotificationSetting: build.query({
      query: () => "admin/notificationsetting",
      providesTags: ["NotificationSetting"],
    }),
    updateNotificationSetting: build.mutation({
      query(data) {
        return {
          url: `admin/notificationsetting`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["NotificationSetting"],
    }),

    // admin, backup
    getBackup: build.query({
      query: () => "admin/backup",
      providesTags: ["Backup"],
    }),
    genBackup: build.mutation({
      query() {
        return {
          url: `admin/backup`,
          method: "POST",
        };
      },
      invalidatesTags: ["Backup"],
    }),

    // client, without login
    getWorkflow: build.query({
      query(param) {
        const { path } = param;
        return {
          url: `client/workflow/${path}`,
          method: "GET",
        };
      },
      providesTags: ["Workflows"],
    }),

    getTrainingStatus: build.query({
      query: (data) => {
        const { id } = data;

        return {
          url: `admin/training/status/${id}`, //id === user._id
          method: "GET",
        };
      },
      providesTags: ["Training"],
    }),
    updateTrainingStatus: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin/training/status/${id}`, //id === user._id
          method: "PATCH",
          body: body,
        };
      },
      invalidatesTags: ["Training"],
    }),
    getTrainingStats: build.query({
      query: () => {
        return {
          url: `admin/training/stats`,
          method: "GET",
        };
      },
      providesTags: ["Training"],
    }),
    getAccountDocumentsFromAdmin: build.query({
      query: () => "admin/account/documents",
      providesTags: ["AccountDocuments"],
    }),
    updateAccountDocumentFromAdmin: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin/account/document/${id}`,
          method: "PATCH",
          body: body,
        };
      },
      invalidatesTags: ["AccountDocuments"],
    }),
    addAccountDocumentFromAdmin: build.mutation({
      query(data) {
        const { ...body } = data;
        return {
          url: `admin/account/document`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["AccountDocuments"],
    }),
    deleteAccountDocumentFromAdmin: build.mutation({
      query(data) {
        const { id } = data;
        return {
          url: `admin/account/document/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["AccountDocuments"],
    }),
    getAccountDocumentFilesFromAdmin: build.query({
      query: (data) => {
        const { id } = data;
        return {
          url: `admin/account/document/files/${id}`,
          method: "GET",
        };
      },
      providesTags: ["AccountDocuments"],
    }),
    uploadAccountDocumentFilesFromAdmin: build.mutation({
      query(param) {
        const { id, data } = param;
        return {
          url: `admin/account/document/files/${id}`,
          method: "POST",
          body: data,
          formData: true,
        };
      },
      invalidatesTags: ["AccountDocuments"],
    }),
    deleteAccountDocumentFilesFromAdmin: build.mutation({
      query(param) {
        const { id, ...body } = param;

        return {
          url: `admin/account/document/files/${id}`,
          method: "DELETE",
          body: body,
        };
      },
      invalidatesTags: ["AccountDocuments"],
    }),
    getClientDocumentsFromAdmin: build.query({
      query: () => "admin/client/documents",
      providesTags: ["ClientDocuments"],
    }),
    updateClientDocumentFromAdmin: build.mutation({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `admin/client/document/${id}`,
          method: "PATCH",
          body: body,
        };
      },
      invalidatesTags: ["ClientDocuments"],
    }),
    addClientDocumentFromAdmin: build.mutation({
      query(data) {
        const { ...body } = data;
        return {
          url: `admin/client/document`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["ClientDocuments"],
    }),
    deleteClientDocumentFromAdmin: build.mutation({
      query(data) {
        const { id } = data;
        return {
          url: `admin/client/document/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["ClientDocuments"],
    }),
    getClientDocumentFilesFromAdmin: build.query({
      query: (data) => {
        const { id } = data;
        return {
          url: `admin/client/document/files/${id}`,
          method: "GET",
        };
      },
      providesTags: ["ClientDocuments"],
    }),
    uploadClientDocumentFilesFromAdmin: build.mutation({
      query(param) {
        const { id, data } = param;
        return {
          url: `admin/client/document/files/${id}`,
          method: "POST",
          body: data,
          formData: true,
        };
      },
      invalidatesTags: ["ClientDocuments"],
    }),
    deleteClientDocumentFilesFromAdmin: build.mutation({
      query(param) {
        const { id, ...body } = param;

        return {
          url: `admin/client/document/files/${id}`,
          method: "DELETE",
          body: body,
        };
      },
      invalidatesTags: ["ClientDocuments"],
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetProductsQuery,
  useGetGeographyQuery,
  useGetSalesQuery,
  useGetAdminsQuery,
  useGetUserPerformanceQuery,
  useGetDashboardQuery,
  useGetTransactionAmountQuery,
  useGetCustomersQuery,
  useAddCustomerMutation,
  useResetCustomerPasswordMutation,
  useUpdateCustomerMutation,
  useDeleteCustomerMutation,
  useGetAdministratorsQuery,
  useAddAdministratorMutation,
  useResetAdministratorPasswordMutation,
  useUpdateAdministratorMutation,
  useDeleteAdministratorMutation,
  useLazyGetCustomerAssetsFromAdminQuery,
  useUpdateCustomerAssetFromAdminMutation,
  useAddCustomerAssetFromAdminMutation,
  useDeleteCustomerAssetFromAdminMutation,
  useGetAllAssetsFromAdminQuery,
  useLazyGetAssetTransactionsFromAdminQuery,
  useUpdateAssetTransactionFromAdminMutation,
  useAddAssetTransactionFromAdminMutation,
  useDeleteAssetTransactionFromAdminMutation,
  useUploadAssetTransactionFilesFromAdminMutation,
  useLazyGetAssetTransactionFilesFromAdminQuery,
  useDeleteAssetTransactionFilesFromAdminMutation,
  useGetAdminDashboardQuery,
  useLazyGetAssetDocumentsFromAdminQuery,
  useUpdateAssetDocumentFromAdminMutation,
  useAddAssetDocumentFromAdminMutation,
  useDeleteAssetDocumentFromAdminMutation,
  useLazyGetAssetDocumentFilesFromAdminQuery,
  useUploadAssetDocumentFilesFromAdminMutation,
  useDeleteAssetDocumentFilesFromAdminMutation,

  useGetQuotationsQuery,
  useUpdateQuotationMutation,
  useAddQuotationMutation,
  useDeleteQuotationMutation,
  useLazyGetQuotationFilesQuery,
  useUploadQuotationFilesMutation,
  useDeleteQuotationFilesMutation,
  useLazyGetQuotationQuery,

  useGetWorkflowsQuery,
  useUpdateWorkflowMutation,
  useAddWorkflowMutation,
  useDeleteWorkflowMutation,
  useLazyGetWorkflowQuery,

  useGetSettingsQuery,
  useUpdateSettingMutation,

  useGetNotificationSettingQuery,
  useUpdateNotificationSettingMutation,

  useGetBackupQuery,
  useGenBackupMutation,

  useLazyGetTrainingStatusQuery,
  useUpdateTrainingStatusMutation,

  useGetTrainingStatsQuery,

  useGetAccountDocumentsFromAdminQuery,
  useUpdateAccountDocumentFromAdminMutation,
  useAddAccountDocumentFromAdminMutation,
  useDeleteAccountDocumentFromAdminMutation,
  useLazyGetAccountDocumentFilesFromAdminQuery,
  useUploadAccountDocumentFilesFromAdminMutation,
  useDeleteAccountDocumentFilesFromAdminMutation,

  useGetClientDocumentsFromAdminQuery,
  useUpdateClientDocumentFromAdminMutation,
  useAddClientDocumentFromAdminMutation,
  useDeleteClientDocumentFromAdminMutation,
  useLazyGetClientDocumentFilesFromAdminQuery,
  useUploadClientDocumentFilesFromAdminMutation,
  useDeleteClientDocumentFilesFromAdminMutation,
} = api;
