// viewQuotation - client (no need to login) to view the quotation details
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Divider,
  Typography,
  useTheme,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Tooltip,
  List,
  Backdrop,
} from "@mui/material";

import { PreviewOutlined as PreviewOutlinedIcon } from "@mui/icons-material";

import logoImage from "assets/logo.png";

import { useLazyGetQuotationQuery } from "state/api";

import { formatBytes } from "../../utils/formateBytes";
import { getClientQuotationFileApi } from "../../features/file/fileActions";

const ViewQuotation = () => {
  const theme = useTheme();
  const { path } = useParams();
  // get the data
  const [trigger, result] = useLazyGetQuotationQuery();
  const [resultFiles, setResultFiles] = useState([]);
  const [selectedQuotationId, setSelectedQuotationId] = useState([]);
  const [previewUrl, setPreviewUrl] = useState("");
  const [showPreview, setShowPreview] = useState(false);

  //for snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleShowPreview = () => {
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const {
    data: quotation,
    error: quotationError,
    isError: isQuotationError,
    isLoading: isLoadingQuotation,
    isFetching: isFetchQuotation,
  } = result;

  useEffect(() => {
    //fetch
    trigger({ path: path });
  }, [path]);

  useEffect(() => {
    if (quotation) {
      setResultFiles(quotation.files);
      setSelectedQuotationId(quotation._id);
    }
  }, [quotation]);

  const handlePreviewFileClick = (id) => async () => {
    try {
      let resp = {};
      resp = await getClientQuotationFileApi(id, selectedQuotationId);
      // get the file type
      const contentType = resp.headers["content-type"];
      // Check the file type and set the preview URL accordingly
      if (contentType.startsWith("image/")) {
        const imageUrl = URL.createObjectURL(new Blob([resp.data]));
        setPreviewUrl(imageUrl);
        handleShowPreview();
      } else {
        // For documents or videos, open a new tab for previewing/downloading
        window.open(
          URL.createObjectURL(new Blob([resp.data], { type: contentType }))
        );
      }
    } catch (error) {
      setSnackbarMessage("Error:" + error);
      // show snackbar
      setOpenSnackbar(true);
    }
  };

  const renderFileList = () => {
    let renderListItem = <></>;
    if (!isLoadingQuotation && resultFiles) {
      renderListItem = resultFiles.map((e, idx) => (
        <ListItem key={e._id}>
          <ListItemText
            primary={`${idx + 1}. ${e.originalname}`}
            secondary={`(File size: ${formatBytes(e.size)} bytes)`}
          />
          <ListItemIcon>
            <IconButton
              edge="end"
              aria-label="preview"
              onClick={handlePreviewFileClick(e._id)}
            >
              <Tooltip title="preview">
                <PreviewOutlinedIcon />
              </Tooltip>
            </IconButton>
          </ListItemIcon>
        </ListItem>
      ));
    }

    const content = (
      <List style={{ minWidth: 100, maxHeight: 400, overflow: "scroll" }}>
        {renderListItem}
      </List>
    );
    return content;
  };
  return (
    <Box
      p="1rem 1rem"
      flex="1 1 100%"
      backgroundColor={theme.palette.background.alt}
      borderRadius="0.55rem"
      style={{ width: "80vw" }}
    >
      <Box
        component="img"
        alt="logo"
        src={logoImage}
        sx={{ objectFit: "cover" }}
      />
      <br />
      <Typography component="h1" variant="h5">
        <center>
          <strong>富 盈 策 略 控 股 有 限 公 司</strong>
        </center>
      </Typography>
      <br />
      <Divider>
        <small>報 價 單</small>
      </Divider>
      <br />
      <b>標 題：</b>
      {quotation?.title} <br />
      <b>簡 要 描 述：</b>
      {quotation?.description} <br />
      <b>檔 案 名 單:</b>
      {renderFileList()}
      <Backdrop
        open={showPreview}
        onClick={handleClosePreview}
        sx={{ zIndex: 99, width: "100%" }}
      >
        <img src={previewUrl} width="200px" />
      </Backdrop>
    </Box>
  );
};

export default ViewQuotation;
