import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";

import { usePatchUserPasswordMutation } from "../../app/services/auth/authService";

import {
  Box,
  Button,
  useTheme,
  useMediaQuery,
  Alert,
  TextField,
  Snackbar,
} from "@mui/material";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useTranslation } from "react-i18next";

const ChangePassword = () => {
  const { t, i18n } = useTranslation();

  const theme = useTheme();
  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [patchUserPassword, { isLoading: isPatching }] =
    usePatchUserPasswordMutation();

  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.auth);

  const schema = yup.object().shape({
    oldPassword: yup
      .string()
      .required(t("changePassword.Old password is required")) // Old password is required
      .min(
        8,
        t("changePassword.Password length should be at least 8 characters")
      )
      .max(
        12,
        t("changePassword.Password cannot exceed more than 12 characters")
      ),
    newPassword: yup
      .string()
      .required(t("changePassword.New password is required"))
      .min(
        8,
        t("changePassword.Password length should be at least 8 characters")
      )
      .max(
        12,
        t("changePassword.Password cannot exceed more than 12 characters")
      ),
    confirmPassword: yup
      .string()
      .required(t("changePassword.New password is required"))
      .min(
        8,
        t("changePassword.Password length should be at least 8 characters")
      )
      .max(
        12,
        t("changePassword.Password cannot exceed more than 12 characters")
      )
      .oneOf(
        [yup.ref("newPassword")],
        t("changePassword.Passwords do not match")
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  // *** snackbar *** //
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const onSubmitHandler = async (data) => {
    // call api to save the data
    try {
      let response = {};
      response = await patchUserPassword({
        data: { oldPassword: data.oldPassword, newPassword: data.newPassword },
      }).unwrap();

      // get message
      if (response.message === "Update Done!") {
        setSnackbarSeverity("success");
        setSnackbarMessage(t("changePassword.Record Updated"));
        setOpenSnackbar(true); //show snackbar
      }
    } catch (error) {
      const error_message = error.data.message;
      setSnackbarSeverity("error");
      if (error_message === "Incorrect Password") {
        setSnackbarMessage(t("changePassword.Incorrect Password"));
      } else if (error_message === "Cannot Perform This Action") {
        setSnackbarMessage(t("changePassword.Cannot Perform This Action"));
      } else {
        setSnackbarMessage("Error! " + error_message);
      }

      setOpenSnackbar(true); //show snackbar
    }

    reset(); //reset form
  };

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header
          title={t("changePassword.Profile")}
          subtitle={t("changePassword.Change Password")}
        />
      </FlexBetween>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{
            width: "100%",
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.background.alt,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        <Box gridColumn="span 4"></Box>
        <Box
          gridColumn="span 4"
          backgroundColor={theme.palette.background.alt}
          p="1rem"
          borderRadius="0.55rem"
        >
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Box sx={{ width: "100%" }}>
              <TextField
                id="oldPassword"
                label={t("changePassword.Old Password")}
                type="password"
                variant="standard"
                required
                sx={{ width: "100%" }}
                inputProps={{ maxLength: 12 }}
                {...register("oldPassword")}
              />
              <p>
                {errors.oldPassword?.message && (
                  <Alert variant="filled" severity="error">
                    {errors.oldPassword?.message}
                  </Alert>
                )}
              </p>

              <TextField
                id="newPassword"
                label={t("changePassword.New Password")}
                type="password"
                variant="standard"
                required
                sx={{ width: "100%" }}
                inputProps={{ maxLength: 12 }}
                {...register("newPassword")}
              />
              <p>
                {errors.newPassword?.message && (
                  <Alert variant="filled" severity="error">
                    {errors.newPassword?.message}
                  </Alert>
                )}
              </p>
              <TextField
                id="confirmPassword"
                label={t("changePassword.Confirm Password")}
                type="password"
                variant="standard"
                required
                sx={{ width: "100%" }}
                inputProps={{ maxLength: 12 }}
                {...register("confirmPassword")}
              />
              <p>
                {errors.confirmPassword?.message && (
                  <Alert variant="filled" severity="error">
                    {errors.confirmPassword?.message}
                  </Alert>
                )}
              </p>
              <Box p="1rem 0rem 0rem 0rem" align="right">
                <Button
                  type="reset"
                  sx={{
                    backgroundColor: theme.palette.primary[200],
                    color: theme.palette.grey[0],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                  }}
                >
                  {t("changePassword.Cancel")}
                </Button>{" "}
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: theme.palette.primary[200],
                    color: theme.palette.grey[0],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                  }}
                >
                  {t("changePassword.Submit")}
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
        <Box gridColumn="span 4"></Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
