import React, { useState, useEffect, useMemo } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  HomeWorkOutlined,
  AccountBalanceOutlined,
  CalendarMonthOutlined,
  CurrencyYenOutlined,
} from "@mui/icons-material";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

import PieChart from "components/PieChart";
import { useGetDashboardQuery, useGetTransactionAmountQuery } from "state/api";
import StatBox from "components/StatBox";

import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";

const currencyFormatter = new Intl.NumberFormat("ja-JP", {
  style: "currency",
  currency: "JPY",
});

const Dashboard = () => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState();

  const theme = useTheme();
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  );

  const getCurrentLocale = () => {
    return t("currentLocale");
  };

  const currentLocale = getCurrentLocale();

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const { data, isLoading } = useGetDashboardQuery();

  const {
    data: transactionAmountData,
    isLoading: isLoadingTranscationAmountData,
  } = useGetTransactionAmountQuery();

  const [creditData, setCreditData] = useState({});
  const [debitData, setDebitData] = useState({});

  useEffect(() => {
    if (!isLoadingTranscationAmountData && transactionAmountData) {
      setCreditData(transactionAmountData.result.credit);
      setDebitData(transactionAmountData.result.debit);
    }
  }, [transactionAmountData]);

  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header
          title={t("dashboard.DASHBOARD")}
          subtitle={t("dashboard.Overview")}
        />
      </FlexBetween>

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {/* ROW 1 */}
        <StatBox
          title={t("dashboard.Total Number of Assets")}
          value={data && data.numOfAssets}
          increase=""
          description=""
          icon={
            <HomeWorkOutlined
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title={t("dashboard.Total Asset Cost")}
          value={data && currencyFormatter.format(data.totalAssetCost)}
          increase=""
          description=""
          icon={
            <AccountBalanceOutlined
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title={t("dashboard.Net Income")}
          value={data && currencyFormatter.format(data.netIncomeThisYear)}
          increase=""
          description={t("dashboard.Current Year")}
          icon={
            <CurrencyYenOutlined
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title={t("dashboard.Net Income")}
          value={data && currencyFormatter.format(data.netIncomeCurrentMonth)}
          increase=""
          description={t("dashboard.Current Month")}
          icon={
            <CalendarMonthOutlined
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />

        {/* ROW 2 */}
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h5" sx={{ color: theme.palette.secondary[100] }}>
            {t("dashboard.Total Credit Amount")}
          </Typography>
          {isLoadingTranscationAmountData && (
            <Typography>Loading...</Typography>
          )}
          {!isLoadingTranscationAmountData && (
            <>
              <PieChart data={creditData} isDashboard={true} />
              <Box sx={{ textAlign: "right", pb: 10 }}>
                <Typography fontSize="1rem">
                  {t("dashboard.Current Year")}
                </Typography>
              </Box>
            </>
          )}
        </Box>
        <Box
          gridColumn="span 6"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h5" sx={{ color: theme.palette.secondary[100] }}>
            {t("dashboard.Total Debit Amount")}
          </Typography>
          {isLoadingTranscationAmountData && (
            <Typography>Loading...</Typography>
          )}
          {!isLoadingTranscationAmountData && (
            <>
              <PieChart data={debitData} isDashboard={true} />
              <Box sx={{ textAlign: "right", pb: 10 }}>
                <Typography fontSize="1rem">
                  {t("dashboard.Current Year")}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
