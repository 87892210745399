import React, { useState, useEffect, useMemo } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import { HomeWorkOutlined } from "@mui/icons-material";
import { Box, useTheme, useMediaQuery, Typography } from "@mui/material";

import PieChart from "components/PieChart";
import JapanMap from "components/JapanMap";
import { useGetAdminDashboardQuery } from "state/api";
import StatBox from "components/StatBox";

import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const AdminDashboard = () => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState();

  const theme = useTheme();
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  );

  const getCurrentLocale = () => {
    return t("currentLocale");
  };

  const currentLocale = getCurrentLocale();

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const { data, isLoading } = useGetAdminDashboardQuery();

  const [mapData, setMapData] = useState([]);

  const renderMap = () => {
    const extensibleArray = [];

    data.mapData.forEach((item) => {
      const obj = {};
      obj["id"] = item.id;
      obj["value"] = item.value;
      extensibleArray.push(obj);
    });

    return <JapanMap data={extensibleArray} />;
  };
  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header
          title={t("adminDashboard.DASHBOARD")}
          subtitle={t("adminDashboard.Overview")}
        />
      </FlexBetween>

      <Box
        mt="20px"
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="160px"
        gap="20px"
        sx={{
          "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
        }}
      >
        {/* ROW 1 */}

        <StatBox
          title={t("adminDashboard.Total Number of Assets")}
          value={data && data.totalNumOfAsset}
          increase=""
          description=""
          icon={
            <HomeWorkOutlined
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title={t("adminDashboard.Total Number of Assets at Tokyo")}
          value={data && data.totalNumOfAssetInTokyo}
          increase=""
          description=""
          icon={
            <HomeWorkOutlined
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title={t("adminDashboard.Total Number of Assets at Osaka")}
          value={data && data.totalNumOfAssetInOsaka}
          increase=""
          description=""
          icon={
            <HomeWorkOutlined
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />
        <StatBox
          title={t("adminDashboard.Total Number of Assets at Fukuoka")}
          value={data && data.totalNumOfAssetInFukuoka}
          increase=""
          description=""
          icon={
            <HomeWorkOutlined
              sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
            />
          }
        />

        <Box
          gridColumn="span 12"
          gridRow="span 3"
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.55rem"
        >
          <Typography variant="h5" sx={{ color: theme.palette.secondary[100] }}>
            {t("adminDashboard.Japanese Property Distribution")}
          </Typography>
          {!isLoading && data && renderMap()}
        </Box>
      </Box>
    </Box>
  );
};

export default AdminDashboard;
