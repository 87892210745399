import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  setMode,
  setDarkMode,
  setLightMode,
} from "../features/themeMode/themeModeSlice";
import { logout } from "features/auth/authSlice";
import { api } from "../state/api";
import { authApi } from "../app/services/auth/authService";
import profileImage from "assets/profile.png";

import {
  ArrowDropDownOutlined,
  ChevronLeftOutlined,
  ChevronRightOutlined,
  DarkModeOutlined,
  LightModeOutlined,
  LanguageOutlined,
  PasswordOutlined,
  LogoutOutlined,
} from "@mui/icons-material";
import FlexBetween from "components/FlexBetween";

import {
  AppBar,
  Button,
  Box,
  Toolbar,
  useTheme,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import { useTranslation } from "react-i18next";

const Navbar = ({ user, isSidebarOpen, setIsSidebarOpen }) => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const [anchorElLang, setAnchorElLang] = useState(null); // for change lang dropdown
  const openLangMenu = Boolean(anchorElLang);
  const handleClickLangMenu = (event) => setAnchorElLang(event.currentTarget);
  const handleCloseLangMenu = () => setAnchorElLang(null);

  const switchLang = (lang) => {
    // console.log("switch lang to: ", lang);
    i18n.changeLanguage(lang);
    handleCloseLangMenu();
  };

  const [anchorEl, setAnchorEl] = useState(null); //for dropdown
  const isOpen = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleLogout = () => {
    dispatch(logout());
    dispatch(api.util.resetApiState()); // reset all api status
    dispatch(authApi.util.resetApiState()); // reset all api status
    navigate("/login");
    setAnchorEl(null);
  };
  const handleChangePassword = () => {
    navigate("/changePassword");
    setAnchorEl(null);
  };

  const storedthemeMode = localStorage.getItem("themeMode");
  const mode = useSelector((state) => state.themeMode.mode);

  const updateThemeModeInLocalStorage = () => {
    // get current mode
    mode === "light"
      ? localStorage.setItem("themeMode", "dark")
      : localStorage.setItem("themeMode", "light");
  };

  return (
    <AppBar
      sx={{
        position: "static",
        background: "none",
        boxShadow: "none",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
            {isSidebarOpen ? (
              <ChevronLeftOutlined sx={{ fontSize: "25px" }} />
            ) : (
              <ChevronRightOutlined sx={{ fontSize: "25px" }} />
            )}
          </IconButton>
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1.5rem">
          <IconButton
            onClick={() => {
              updateThemeModeInLocalStorage();
              dispatch(setMode());
            }}
          >
            {theme.palette.mode === "dark" ? (
              <DarkModeOutlined sx={{ fontSize: "25px" }} />
            ) : (
              <LightModeOutlined sx={{ fontSize: "25px" }} />
            )}
          </IconButton>
          <IconButton onClick={handleClickLangMenu}>
            <LanguageOutlined />
          </IconButton>
          <Menu
            id="lang-menu"
            anchorEl={anchorElLang}
            open={openLangMenu}
            onClose={handleCloseLangMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => switchLang("en")}>EN</MenuItem>
            <MenuItem onClick={() => switchLang("zh-Hant")}>
              {"\u7e41"}
            </MenuItem>
          </Menu>

          <FlexBetween>
            <Button
              onClick={handleClick}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
                gap: "1rem",
              }}
            >
              <Box
                component="img"
                alt="profile"
                src={profileImage}
                height="32px"
                width="32px"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              />
              <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.85rem"
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {user.name}
                </Typography>
              </Box>
              <ArrowDropDownOutlined
                sx={{ color: theme.palette.secondary[300], fontSize: "25px" }}
              />
            </Button>

            <Menu
              anchorEl={anchorEl}
              open={isOpen}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <MenuItem onClick={handleChangePassword}>
                <ListItemIcon>
                  <PasswordOutlined />
                </ListItemIcon>
                {t("navbar.Change Password")}
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutOutlined />
                </ListItemIcon>
                {t("navbar.Logout")}
              </MenuItem>
            </Menu>
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
