import React, { useEffect, useMemo, useState } from "react";

import Header from "components/Header";

import {
  useGetNotificationSettingQuery,
  useUpdateNotificationSettingMutation,
} from "state/api";

import {
  Box,
  Button,
  CircularProgress,
  useTheme,
  TextField,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormLabel,
  useMediaQuery,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  useGridApiContext,
  GRID_DATE_COL_DEF,
} from "@mui/x-data-grid";

import {
  Add as AddIcon,
  Save as SaveIcon,
  Close as CancelIcon,
  Edit as EditIcon,
  DeleteOutlined as DeleteIcon,
  YoutubeSearchedFor as ViewSettingIcon,
} from "@mui/icons-material";

import { Alert as MuiAlert, Tooltip } from "@mui/material";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NotificationSetting = () => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState();
  const theme = useTheme();
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  );

  const getCurrentLocale = () => {
    return t("currentLocale");
  };

  const currentLocale = getCurrentLocale();

  // set locale for datagrid
  useEffect(() => {
    if (currentLocale === "zh-Hant") {
      setLocale("zhHK");
    } else {
      setLocale("enUS");
    }
  }, [currentLocale]);

  const { data: setting, isLoading: isLoadingSetting } =
    useGetNotificationSettingQuery(); // get all workflow

  useEffect(() => {
    if (typeof setting !== "undefined" && setting.length > 0) {
      // always use the 1st entry
      setOrgSetting(setting[0]);
      setFormWhatsappGroup(setting[0].whatsappGroup);
      setFormStatus(setting[0].status);
    } else {
      //use default
      setOrgSetting({ whatsappGroup: "", status: "disabled" });
      setFormWhatsappGroup("");
      setFormStatus("disabled");
    }
  }, [setting]);

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [patchSetting, { isLoading: isPatching }] =
    useUpdateNotificationSettingMutation();

  //reset form
  const handleReset = () => {
    reset(orgSetting);
    setFormWhatsappGroup(orgSetting.whatsappGroup);
    setFormStatus(orgSetting.status);
  };

  //form data
  const [orgSetting, setOrgSetting] = useState({});
  const [formWhatsappGroup, setFormWhatsappGroup] = useState("");
  const [formStatus, setFormStatus] = useState("");

  const handleWhatsappGroupChange = (event) => {
    setFormWhatsappGroup(event.target.value);
    setValue("whatsappGroup", event.target.value);
  };

  const handleStatusChange = (event) => {
    setFormStatus(event.target.value);
    // setValue("status", event.target.value);
  };

  // *** snackbar *** //
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const onSubmit = async (data) => {
    // call api to save the data
    try {
      let response = {};
      response = await patchSetting({
        data: {
          whatsappGroup: formWhatsappGroup,
          status: formStatus,
        },
      }).unwrap();

      // get message
      if (response.message === "Done!") {
        setSnackbarSeverity("success");
        setSnackbarMessage(t("notification setting.Notification Set"));
        setOpenSnackbar(true); //show snackbar
      }
    } catch (error) {
      const error_message = error.data.message;
      setSnackbarSeverity("error");
      setSnackbarMessage("Error! " + error_message);
      setOpenSnackbar(true); //show snackbar
    }

    reset(); //reset form
  };

  const schema = yup.object().shape({
    whatsappGroup: yup.string(),
    status: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const inputForm = () => {
    let content;
    content = (
      <Box m="1.5rem 2.5rem">
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{
              width: "100%",
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Box>
          <Box gridColumn="span 4"></Box>
          <Box
            gridColumn="span 4"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ width: "100%" }}>
                <TextField
                  id="whatsappGroup"
                  label={t("notification setting.whatsappGroup")}
                  type="text"
                  variant="standard"
                  sx={{ width: "100%" }}
                  {...register("whatsappGroup")}
                  value={formWhatsappGroup}
                  onChange={handleWhatsappGroupChange}
                />
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="lbl_status">
                    {t("notification setting.status")}
                  </InputLabel>

                  <Select
                    labelId="lbl_status"
                    id="status"
                    value={formStatus}
                    onChange={handleStatusChange}
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value="enable">
                      {t("notification setting.Enable")}
                    </MenuItem>
                    <MenuItem value="disable">
                      {t("notification setting.Disable")}
                    </MenuItem>
                  </Select>
                </FormControl>
                <Box p="1rem 0rem 0rem 0rem" align="right">
                  <Button
                    type="reset"
                    sx={{
                      backgroundColor: theme.palette.primary[200],
                      color: theme.palette.grey[0],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                    onClick={handleReset}
                  >
                    {t("notification setting.Reset")}
                  </Button>{" "}
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: theme.palette.primary[200],
                      color: theme.palette.grey[0],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                  >
                    {t("notification setting.Submit")}
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
          <Box gridColumn="span 4"></Box>
        </Box>
      </Box>
    );
    return content;
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title={t("notification setting.NOTIFICATION SETTING")}
        subtitle=""
      />
      {inputForm()}
    </Box>
  );
};

export default NotificationSetting;
