import React, { useEffect, useMemo, useState } from "react";

import Header from "components/Header";

import {
  useGetWorkflowsQuery,
  useUpdateWorkflowMutation,
  useAddWorkflowMutation,
  useDeleteWorkflowMutation,
} from "state/api";

import {
  Box,
  Button,
  CircularProgress,
  useTheme,
  TextField,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  useGridApiContext,
  GRID_DATE_COL_DEF,
} from "@mui/x-data-grid";

import {
  Add as AddIcon,
  Save as SaveIcon,
  Close as CancelIcon,
  Edit as EditIcon,
  DeleteOutlined as DeleteIcon,
  YoutubeSearchedFor as ViewWorkflowIcon,
} from "@mui/icons-material";

import { Alert as MuiAlert, Tooltip } from "@mui/material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const dateColumnType = {
  ...GRID_DATE_COL_DEF,
  resizable: false,
  renderEditCell: (params) => <GridEditDateCell {...params} />,
};

function GridEditDateCell({ id, field, value }) {
  const apiRef = useGridApiContext();

  function handleChange(newValue) {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  }

  return (
    <DatePicker
      value={value}
      renderInput={(params) => <TextField {...params} />}
      onChange={handleChange}
    />
  );
}

function EditToolbar(props) {
  const { t, i18n } = useTranslation();
  const { setRows, setRowModesModel, rows } = props;

  const handleAddRecordClick = () => {
    //get the row id

    const newId =
      rows.length === 0
        ? 1
        : Math.max(...rows.map((r) => (r.id ? r.id : 0) * 1)) + 1;
    const id = newId;
    setRows((oldRows) => [
      ...oldRows,
      {
        id: newId,
        customerId: "",
        transactionDate: "",
        transactionType: "",
        transactionDetail: "",
        amount: 0,
        numOfFiles: 0,
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "transactionType" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleAddRecordClick}
      >
        {t("workflows.ADD WORKFLOW")}
      </Button>
    </GridToolbarContainer>
  );
}

const Workflows = () => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState();
  const theme = useTheme();
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  );

  const getCurrentLocale = () => {
    return t("currentLocale");
  };

  const currentLocale = getCurrentLocale();

  // set locale for datagrid
  useEffect(() => {
    if (currentLocale === "zh-Hant") {
      setLocale("zhHK");
    } else {
      setLocale("enUS");
    }
  }, [currentLocale]);

  const { data: workflows, isLoading: isLoadingWorkflows } =
    useGetWorkflowsQuery(); // get all workflow

  const [rows, setRows] = useState();
  const [rowModesModel, setRowModesModel] = useState({});

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  //for snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  //for file modal
  const [updateWorkflow, { isLoading: isUpdating }] =
    useUpdateWorkflowMutation();

  const [addWorkflow, { isLoading: isAdding }] = useAddWorkflowMutation();

  const [deleteWorkflow, { isLoading: isDeleting }] =
    useDeleteWorkflowMutation();

  // update the data grid row, if loaded with new data
  useEffect(() => {
    setRows(workflows);
  }, [workflows]);

  // *** snackbar *** //
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  // *** confirm delete dialog *** //
  const handleCloseConfirmDeleteDialog = () => {
    setOpenConfirmDeleteDialog(false);
  };

  // *** confirm delete file dialog *** //
  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleDeleteClick = (id) => () => {
    // save the row id
    setDeleteId(id);
    setOpenConfirmDeleteDialog(true);
  };

  const handleViewWorkflowClick = (id) => () => {
    let selectedRow = rows.find((row) => row.id === id);
    // const url = "client/workflow/" + selectedRow["path"];
    const url = "wx/" + selectedRow["path"];
    window.open(url, "_blank"); //to open new page
  };

  const handleSubmitConfirmDeleteDialog = async () => {
    let selectedRow = rows.find((row) => row.id === deleteId);
    // // call api to delete the administrator
    try {
      let response = {};
      //updating
      response = await deleteWorkflow({
        id: selectedRow["_id"], // actual id
      }).unwrap();
      // get message
      if (response.message === "Delete Done!") {
        // set the snackbar message
        setSnackbarMessage(t("workflows.snackbar.Record Deleted"));
        // show snackbar
        setOpenSnackbar(true);
      }
    } catch (error) {
      // set the snackbar message
      setSnackbarMessage("Error: " + error);
      // show snackbar
      setOpenSnackbar(true);
    }
    //update the display
    setRows(rows.filter((row) => row.id !== deleteId));
    //reset
    setDeleteId("");
    setOpenConfirmDeleteDialog(false);
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleProcessRowUpdateError = React.useCallback((error) => {
    // showSnackbar("error", error.message);
    //setSnackbarMessage("Error! - " + error.message);
  }, []);
  const processRowUpdate = async (newRow) => {
    try {
      let response = {};
      if (newRow["isNew"]) {
        //insert new record
        response = await addWorkflow({
          data: newRow,
        }).unwrap();
        //get _id
        const new_id = response._id;
        newRow["_id"] = new_id;
      } else {
        //updating
        response = await updateWorkflow({
          id: newRow["_id"],
          data: newRow,
        }).unwrap();
      }
      //get response message
      if (
        response.message === "Update Done!" ||
        response.message === "Add Done!" ||
        response.message === "Delete Done!"
      ) {
        // set the snackbar message
        if (response.message === "Update Done!")
          setSnackbarMessage(t("workflows.snackbar.Record Updated"));
        else if (response.message === "Add Done!")
          setSnackbarMessage(t("workflows.snackbar.Record Added"));
        else if (response.message === "Delete Done!")
          setSnackbarMessage(t("workflows.snackbar.Record Deleted"));
        // show snackbar
        setOpenSnackbar(true);
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
      }
    } catch (error) {
      return null;
    }
  };

  const columns = [
    { field: "id", headerName: "No", flex: 0.01, editable: false },
    {
      field: "title",
      headerName: t("workflows.header.Title"),
      flex: 0.05,
      editable: true,
      type: "text",
    },
    {
      field: "description",
      headerName: t("workflows.header.Description"),
      flex: 0.05,
      editable: true,
      type: "text",
    },
    {
      field: "remark",
      headerName: t("workflows.header.Remark"),
      flex: 0.05,
      editable: true,
      type: "text",
    },
    {
      field: "currentStep",
      headerName: t("workflows.header.Current Step"),
      flex: 0.05,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        {
          value: 1,
          label: t("workflows.select.step1"),
        },
        {
          value: 2,
          label: t("workflows.select.step2"),
        },
        {
          value: 3,
          label: t("workflows.select.step3"),
        },
        {
          value: 4,
          label: t("workflows.select.step4"),
        },
        {
          value: 5,
          label: t("workflows.select.step5"),
        },
      ],
    },
    {
      field: "zoomLink",
      headerName: t("workflows.header.Zoom Link"),
      flex: 0.05,
      editable: true,
      type: "text",
    },
    {
      field: "zoomDate",
      ...dateColumnType,
      headerName: t("workflows.header.Zoom Date"),
      flex: 0.05,
      editable: true,
      valueGetter: ({ value }) => new Date(value),
    },
    {
      field: "zoomTime",
      headerName: t("workflows.header.Zoom Time"),
      flex: 0.05,
      editable: true,
      type: "text",
    },
    {
      field: "zoomPeriodInHour",
      headerName: t("workflows.header.Zoom Period"),
      flex: 0.05,
      editable: true,
      type: "number",
      default: 1,
    },

    {
      field: "actions",
      type: "actions",
      headerName: t("workflows.header.Actions"),
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode && !isUpdating && !isAdding) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        if (isUpdating || isAdding) {
          return [
            <>
              <CircularProgress size="1.5rem" />
            </>,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <Tooltip title={t("workflows.menu.Edit Workflow")}>
                <EditIcon />
              </Tooltip>
            }
            label={t("workflows.menu.Edit Workflow")}
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title={t("workflows.menu.View Workflow")}>
                <ViewWorkflowIcon />
              </Tooltip>
            }
            label={t("workflows.menu.View Workflow")}
            className="textPrimary"
            color="inherit"
            onClick={handleViewWorkflowClick(id)}
          />,

          <GridActionsCellItem
            icon={<DeleteIcon />}
            label={t("workflows.menu.Delete Workflow")}
            onClick={handleDeleteClick(id)}
            showInMenu
          />,
        ];
      },
    },
  ];

  const tableGrid = () => {
    let content;
    content = (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {/** Confirm Dialog */}
        <Dialog
          open={openConfirmDeleteDialog}
          onClose={handleCloseConfirmDeleteDialog}
        >
          <DialogContent
            sx={{
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
            }}
          >
            <DialogContentText>
              {t("workflows.dialog.Confirm Delete")}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
            }}
          >
            <Button
              onClick={handleCloseConfirmDeleteDialog}
              sx={{
                backgroundColor: theme.palette.background.alt,
                color: `${theme.palette.secondary[100]} !important`,
              }}
            >
              {t("workflows.dialog.CANCEL")}
            </Button>
            <Button
              onClick={handleSubmitConfirmDeleteDialog}
              sx={{
                backgroundColor: theme.palette.background.alt,
                color: `${theme.palette.secondary[100]} !important`,
              }}
            >
              {t("workflows.dialog.DELETE")}
            </Button>
          </DialogActions>
        </Dialog>
        {/** Snackbar */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={1000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            sx={{
              width: "100%",
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <ThemeProvider theme={themeWithLocale}>
            <DataGrid
              loading={isLoadingWorkflows || !workflows}
              rows={rows || []}
              columns={columns}
              getRowId={(row) => row.id}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              slots={{
                toolbar: EditToolbar,
              }}
              slotProps={{
                toolbar: { setRows, setRowModesModel, rows },
              }}
            />
          </ThemeProvider>
        </Box>
      </LocalizationProvider>
    );
    return content;
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title={t("workflows.WORKFLOWS")}
        subtitle={t("workflows.List of Workflows")}
      />
      {tableGrid()}
    </Box>
  );
};

export default Workflows;
