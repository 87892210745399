import React, { useEffect, useMemo, useState } from "react";

import Header from "components/Header";

import { useGetBackupQuery, useGenBackupMutation } from "state/api";

import {
  Box,
  Button,
  CircularProgress,
  useTheme,
  TextField,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormLabel,
  useMediaQuery,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  useGridApiContext,
  GRID_DATE_COL_DEF,
} from "@mui/x-data-grid";

import {
  Add as AddIcon,
  Save as SaveIcon,
  Close as CancelIcon,
  Edit as EditIcon,
  DeleteOutlined as DeleteIcon,
  YoutubeSearchedFor as ViewSettingIcon,
} from "@mui/icons-material";

import { Alert as MuiAlert, Tooltip } from "@mui/material";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Backup = () => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState();
  const theme = useTheme();
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  );

  const getCurrentLocale = () => {
    return t("currentLocale");
  };

  const currentLocale = getCurrentLocale();

  // set locale for datagrid
  useEffect(() => {
    if (currentLocale === "zh-Hant") {
      setLocale("zhHK");
    } else {
      setLocale("enUS");
    }
  }, [currentLocale]);

  const { data: setting, isLoading: isLoadingSetting } = useGetBackupQuery(); // get all workflow

  useEffect(() => {
    if (typeof setting !== "undefined" && setting.length > 0) {
      // use loaded data
    }
  }, [setting]);

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [genBackup, { isLoading: isGenerating }] = useGenBackupMutation();

  const [loading, setLoading] = useState(false);

  // *** snackbar *** //
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleBackupClick = async (data) => {
    // call api to save the data
    setLoading(true);
    try {
      console.log("handle backup click");
      let response = {};
      response = await genBackup().unwrap();
      console.log(response);
      // get message
      if (response.message === "Done!") {
        setSnackbarSeverity("success");
        setSnackbarMessage(t("backup.backup finished"));
        setOpenSnackbar(true); //show snackbar
      }
    } catch (error) {
      const error_message = error.data.message;
      setSnackbarSeverity("error");
      setSnackbarMessage("Error! " + error_message);
      setOpenSnackbar(true); //show snackbar
    } finally {
      setLoading(false);
    }
  };

  const inputForm = () => {
    let content;
    content = (
      <Box m="1.5rem 2.5rem">
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{
              width: "100%",
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Box>
          <Box gridColumn="span 4"></Box>
          <Box
            gridColumn="span 4"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            <Box sx={{ width: "100%" }}>
              <Box p="1rem 0rem 0rem 0rem" align="right">
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: theme.palette.primary[200],
                    color: theme.palette.grey[0],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                  }}
                  onClick={handleBackupClick}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress size={24} />
                  ) : (
                    t("backup.Start Backup")
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box gridColumn="span 4"></Box>
        </Box>
      </Box>
    );
    return content;
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title={t("backup.BACKUP")} subtitle="" />
      {inputForm()}
    </Box>
  );
};

export default Backup;
