import React, { useEffect, useMemo, useState } from "react";

import Header from "components/Header";

import { useGetTrainingStatsQuery } from "state/api";

import { Box, useTheme } from "@mui/material";

import { DataGrid } from "@mui/x-data-grid";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const TrainingStats = () => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState();
  const theme = useTheme();
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  );

  const getCurrentLocale = () => {
    return t("currentLocale");
  };

  const currentLocale = getCurrentLocale();

  // set locale for datagrid
  useEffect(() => {
    if (currentLocale === "zh-Hant") {
      setLocale("zhHK");
    } else {
      setLocale("enUS");
    }
  }, [currentLocale]);

  const { data, isLoading } = useGetTrainingStatsQuery();

  let rowData = [];

  useEffect(() => {
    if (!isLoading) {
      rowData = [];

      let idx = 1;
      for (let i in data) {
        let firstName = data[i].firstName;
        let lastName = data[i].lastName;
        let position = data[i].position;
        let completedCourses = data[i].completedCourses;

        let r = {};
        r["id"] = idx++;
        r["firstName"] = firstName;
        r["lastName"] = lastName;
        r["position"] = position;
        r["completedCourses"] = completedCourses;

        rowData.push(r);
      }
    }

    setRows(rowData);
  }, [isLoading]);

  const [rows, setRows] = useState();
  const [rowModesModel, setRowModesModel] = useState({});

  const columns = [
    { field: "id", headerName: "No", flex: 0.01, editable: false },
    {
      field: "lastName",
      headerName: t("trainingstats.header.Last Name"),
      flex: 0.05,
      editable: true,
      type: "text",
    },
    {
      field: "firstName",
      headerName: t("trainingstats.header.First Name"),
      flex: 0.05,
      editable: true,
      type: "text",
    },
    {
      field: "position",
      headerName: t("trainingstats.header.Position"),
      flex: 0.05,
      editable: true,
      type: "text",
    },

    {
      field: "completedCourses",
      headerName: t("trainingstats.header.Progress"),
      flex: 0.05,
      editable: true,
      type: "text",
      valueFormatter: ({ value }) => (value / 10.0) * 100 + "%",
    },
  ];

  const tableGrid = () => {
    let content;
    content = (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <ThemeProvider theme={themeWithLocale}>
            <DataGrid
              loading={isLoading || !data}
              rows={rows || []}
              columns={columns}
              getRowId={(row) => row.id}
              editMode="row"
              rowModesModel={rowModesModel}
              slotProps={{
                toolbar: { setRows, setRowModesModel, rows },
              }}
            />
          </ThemeProvider>
        </Box>
      </LocalizationProvider>
    );
    return content;
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title={t("trainingstats.STAFF TRAINING STATISTICS")}
        subtitle={t("trainingstats.List of Staff")}
      />
      {tableGrid()}
    </Box>
  );
};

export default TrainingStats;
