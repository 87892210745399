import React, { useEffect, useMemo, useState, useCallback } from "react";
import Header from "components/Header";
import {
  useGetCustomersQuery,
  useUpdateCustomerMutation,
  useAddCustomerMutation,
  useResetCustomerPasswordMutation,
  useDeleteCustomerMutation,
} from "state/api";

import { generatePassword } from "../../utils/generatePassword";

import { useNavigate } from "react-router-dom";

import {
  Alert as MuiAlert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";

import {
  Add as AddIcon,
  Close as CancelIcon,
  DeleteOutlined as DeleteIcon,
  Edit as EditIcon,
  PasswordOutlined as ResetPasswordIcon,
  RoomPreferencesOutlined as ManageAssetIcon,
  Save as SaveIcon,
  Security as SecurityIcon,
} from "@mui/icons-material";

import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
} from "@mui/x-data-grid";

import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

function EditToolbar(props) {
  const { t, i18n } = useTranslation();
  const { setRows, setRowModesModel, rows } = props;

  const handleAddRecordClick = () => {
    //get the row id

    const newId =
      rows.length === 0
        ? 1
        : Math.max(...rows.map((r) => (r.id ? r.id : 0) * 1)) + 1;
    const id = newId;
    setRows((oldRows) => [
      ...oldRows,
      {
        id: newId,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "firstName" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleAddRecordClick}
      >
        {t("customers.Add Customer")}
      </Button>
    </GridToolbarContainer>
  );
}
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Customers = () => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState();

  const theme = useTheme();
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  );

  const getCurrentLocale = () => {
    return t("currentLocale");
  };

  const currentLocale = getCurrentLocale();

  // set locale for datagrid
  useEffect(() => {
    if (currentLocale === "zh-Hant") {
      setLocale("zhHK");
    } else {
      setLocale("enUS");
    }
  }, [currentLocale]);

  const { data, isLoading } = useGetCustomersQuery(); // get user role data
  const [rows, setRows] = useState();
  const [rowModesModel, setRowModesModel] = useState({});
  const navigate = useNavigate();

  // for dialog error message
  const [errorMessage, setErrorMessage] = useState(null);

  //for snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // for reset password form
  const [openResetPasswordForm, setOpenResetPasswordForm] = useState(false);
  const [resetId, setResetId] = useState("");
  const [resetPassword, setResetPassword] = useState("");

  // for delete confirm dialog
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  // *** API *** //
  const [updateCustomer, { isLoading: isUpdating }] =
    useUpdateCustomerMutation();

  const [addCustomer, { isLoading: isAdding }] = useAddCustomerMutation();

  const [resetCustomerPassword, { isLoading: isResetting }] =
    useResetCustomerPasswordMutation();

  const [deleteCustomer, { isLoading: isDeleting }] =
    useDeleteCustomerMutation();

  useEffect(() => {
    setRows(data);
  }, [isLoading]);

  // *** snackbar *** //
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  // *** generate password ***//
  const handleGeneratePassword = async (e) => {
    const generatedPW = generatePassword(8);
    //set reset password
    setResetPassword(generatedPW);
  };

  // *** confirm delete dialog *** //
  const handleCloseConfirmDeleteDialog = () => {
    setDeleteId("");
    setOpenConfirmDeleteDialog(false);
  };

  const handleSubmitConfirmDeleteDialog = async () => {
    let selectedRow = rows.find((row) => row.id === deleteId);
    // call api to delete the customer
    try {
      let response = {};
      //updating
      response = await deleteCustomer({
        id: selectedRow["_id"], // actual id
      }).unwrap();

      // get message
      if (response.message === "Delete Done!") {
        // set the snackbar message
        setSnackbarMessage(t("customers.snackbar.Record Deleted"));

        // show snackbar
        setOpenSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage("Error: " + error);
      // show snackbar
      setOpenSnackbar(true);
    }
    //update the display
    setRows(rows.filter((row) => row.id !== deleteId));
    //reset
    setDeleteId("");
    setOpenConfirmDeleteDialog(false);
  };

  // *** reset password form *** //
  const handleCloseResetPasswordForm = () => {
    setResetId("");
    setResetPassword("");
    setErrorMessage(null);
    setOpenResetPasswordForm(false);
  };
  const handleSubmitResetPasswordForm = async (e) => {
    e.preventDefault();
    //checking
    if (resetPassword.length < 8) {
      setErrorMessage(
        t("customers.dialog.Password length should be at least 8 characters")
      );
      return;
    }
    let selectedRow = rows.find((row) => row.id === resetId);
    // call api to save the data
    try {
      let response = {};
      //updating
      response = await resetCustomerPassword({
        id: selectedRow["_id"], // actual id
        data: { password: resetPassword },
      }).unwrap();

      // get message
      if (response.message === "Update Done!") {
        // set the snackbar message
        setSnackbarMessage(t("customers.snackbar.Record Updated"));

        // show snackbar
        setOpenSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage("Error: " + error);
      // show snackbar
      setOpenSnackbar(true);
    }

    setOpenResetPasswordForm(false);
    setResetId("");
    setResetPassword("");
    setErrorMessage(null);
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleManageAssetClick = (id) => () => {
    let selectedRow = rows.find((row) => row.id === id);
    navigate("/assets", { state: { user_id: selectedRow["_id"] } });
  };

  const handlePasswordFieldChange = (e) => {
    setResetPassword(e.target.value);
    if (errorMessage) setErrorMessage(null);
  };

  const handleResetPasswordClick = (id) => () => {
    //show a popup to enter the information
    setResetId(id);
    setOpenResetPasswordForm(true);
  };

  const handleDeleteClick = (id) => () => {
    //setRows(rows.filter((row) => row.id !== id));
    // save the row id
    setDeleteId(id);
    setOpenConfirmDeleteDialog(true);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleProcessRowUpdateError = React.useCallback((error) => {
    // showSnackbar("error", error.message);
    setSnackbarMessage("Error! - " + error.message);
  }, []);

  const processRowUpdate = async (newRow) => {
    try {
      let response = {};
      if (newRow["isNew"]) {
        //insert new record
        response = await addCustomer({
          data: newRow,
        }).unwrap();

        //get _id
        const new_id = response._id; //customer _id
        newRow["_id"] = new_id;
      } else {
        //updating
        response = await updateCustomer({
          id: newRow["_id"],
          data: newRow,
        }).unwrap();
      }

      // get message
      if (
        response.message === "Update Done!" ||
        response.message === "Add Done!" ||
        response.message === "Delete Done!"
      ) {
        // set the snackbar message
        if (response.message === "Update Done!")
          setSnackbarMessage(t("customers.snackbar.Record Updated"));
        else if (response.message === "Add Done!")
          setSnackbarMessage(t("customers.snackbar.Record Added"));
        else if (response.message === "Delete Done!")
          setSnackbarMessage(t("customers.snackbar.Record Deleted"));
        // show snackbar
        setOpenSnackbar(true);

        let updatedRow = { ...newRow, isNew: false };

        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
      }
    } catch (error) {
      return null;
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: "id", headerName: "No", flex: 0.01, editable: false },
    {
      field: "lastName",
      headerName: t("customers.header.Last Name"),
      flex: 0.05,
      editable: true,
    },
    {
      field: "firstName",
      headerName: t("customers.header.First Name"),
      flex: 0.05,
      editable: true,
    },
    {
      field: "email",
      headerName: t("customers.header.Email"),
      flex: 0.05,
      editable: true,
    },
    {
      field: "phone",
      headerName: t("customers.header.Phone"),
      flex: 0.05,
      editable: true,
    },
    {
      field: "assetCount",
      headerName: t("customers.header.Num of Assets"),
      flex: 0.05,
      editable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: t("customers.header.Actions"),
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode && !isUpdating && !isAdding && !isResetting) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        if (isUpdating || isAdding || isResetting) {
          return [
            <>
              <CircularProgress size="1.5rem" />
            </>,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <Tooltip title={t("customers.menu.Edit Customer")}>
                <EditIcon />
              </Tooltip>
            }
            label={t("customers.menu.Edit Customer")}
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title={t("customers.menu.Manage Asset")}>
                <ManageAssetIcon />
              </Tooltip>
            }
            label={t("customers.menu.Manage Asset")}
            className="textPrimary"
            onClick={handleManageAssetClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title={t("customers.menu.Reset Password")}>
                <ResetPasswordIcon />
              </Tooltip>
            }
            label="Reset Password"
            onClick={handleResetPasswordClick(id)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label={t("customers.menu.Delete Customer")}
            onClick={handleDeleteClick(id)}
            showInMenu
          />,
        ];
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title={t("customers.CUSTOMERS")}
        subtitle={t("customers.List of Customers")}
      />
      {/** reset password form */}
      <Box>
        {/** Confirm Dialog */}
        <Dialog
          open={openConfirmDeleteDialog}
          onClose={handleCloseConfirmDeleteDialog}
        >
          <DialogContent
            sx={{
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
            }}
          >
            <DialogContentText>
              {t("customers.dialog.Confirm Delete")}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
            }}
          >
            <Button
              onClick={handleCloseConfirmDeleteDialog}
              sx={{
                backgroundColor: theme.palette.background.alt,
                color: `${theme.palette.secondary[100]} !important`,
              }}
            >
              {t("customers.dialog.CANCEL")}
            </Button>
            <Button
              onClick={handleSubmitConfirmDeleteDialog}
              sx={{
                backgroundColor: theme.palette.background.alt,
                color: `${theme.palette.secondary[100]} !important`,
              }}
            >
              {t("customers.dialog.DELETE")}
            </Button>
          </DialogActions>
        </Dialog>

        {/** Reset Password Dialog */}
        <Dialog
          open={openResetPasswordForm}
          onClose={handleCloseResetPasswordForm}
        >
          <form id="reset_password" onSubmit={handleSubmitResetPasswordForm}>
            <DialogContent
              sx={{
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
              }}
            >
              <TextField
                autoFocus
                margin="dense"
                id="newPassword"
                label={t("customers.dialog.Please Enter New Password")}
                type="text"
                fullWidth
                variant="standard"
                required
                value={resetPassword}
                sx={{
                  width: "100%",
                  backgroundColor: theme.palette.background.alt,
                  color: `${theme.palette.secondary[200]} !important`,
                }}
                inputProps={{ maxLength: 12 }}
                onChange={(e) => {
                  handlePasswordFieldChange(e);
                }}
              />
              {errorMessage && (
                <Alert variant="filled" severity="error">
                  {errorMessage}
                </Alert>
              )}
            </DialogContent>
            <DialogActions
              sx={{
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
              }}
            >
              <Button
                sx={{
                  backgroundColor: theme.palette.background.alt,
                  color: `${theme.palette.secondary[100]} !important`,
                }}
                onClick={handleGeneratePassword}
              >
                {t("customers.dialog.Generate Password")}
              </Button>
              <Button
                type="reset"
                onClick={() => {
                  handleCloseResetPasswordForm();
                }}
                sx={{
                  backgroundColor: theme.palette.background.alt,
                  color: `${theme.palette.secondary[100]} !important`,
                }}
              >
                {t("customers.dialog.CANCEL")}
              </Button>
              <Button
                type="submit"
                sx={{
                  backgroundColor: theme.palette.background.alt,
                  color: `${theme.palette.secondary[100]} !important`,
                }}
              >
                {t("customers.dialog.SAVE")}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          //   severity={snackbarVariant}
          sx={{
            width: "100%",
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.background.alt,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Box
        mt="40px"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <ThemeProvider theme={themeWithLocale}>
          <DataGrid
            loading={isLoading || !data}
            rows={rows || []}
            columns={columns}
            getRowId={(row) => row.id}
            editMode="row"
            rowModesModel={rowModesModel}
            onRowModesModelChange={handleRowModesModelChange}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            onProcessRowUpdateError={handleProcessRowUpdateError}
            slots={{
              toolbar: EditToolbar,
            }}
            slotProps={{
              toolbar: { setRows, setRowModesModel, rows },
            }}
          />
        </ThemeProvider>
      </Box>
    </Box>
  );
};

export default Customers;
