export const isExpired = (token) => {
  if (token) {
    const decode = JSON.parse(atob(token.split(".")[1]));
    if (decode.exp * 1000 < new Date().getTime()) {
      //console.log("Time Expired");
      return true;
    }
  }
  return false;
};
