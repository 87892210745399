import React, { useEffect, useMemo, useState } from "react";

import Header from "components/Header";

import {
  //   useUpdateWorkflowMutation,
  useAddWorkflowMutation,
  useDeleteWorkflowMutation,
  useLazyGetTrainingStatusQuery,
  useUpdateTrainingStatusMutation,
} from "state/api";

import { Box, useTheme, TextField, Snackbar, Link } from "@mui/material";

import { createFilterOptions } from "@mui/material/Autocomplete";
import {
  GridRowModes,
  DataGrid,
  GridActionsCellItem,
  useGridApiContext,
  GRID_DATE_COL_DEF,
} from "@mui/x-data-grid";

import {
  FactCheck as MarkAsFinishedIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CloseCircleIcon,
} from "@mui/icons-material";

import { Alert as MuiAlert, Tooltip } from "@mui/material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function GridEditDateCell({ id, field, value }) {
  const apiRef = useGridApiContext();

  function handleChange(newValue) {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  }

  return (
    <DatePicker
      value={value}
      renderInput={(params) => <TextField {...params} />}
      onChange={handleChange}
    />
  );
}

const Training = () => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState();
  const theme = useTheme();
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  );

  const getCurrentLocale = () => {
    return t("currentLocale");
  };

  const currentLocale = getCurrentLocale();

  // set locale for datagrid
  useEffect(() => {
    if (currentLocale === "zh-Hant") {
      setLocale("zhHK");
    } else {
      setLocale("enUS");
    }
  }, [currentLocale]);

  const [trigger, result] = useLazyGetTrainingStatusQuery();

  const {
    data: trainingStatus,
    error: trainingStatusError,
    isError: isTrainingStatusError,
    isLoading: isLoadingTrainingStatus,
    isFetching: isFetchTrainingStatus,
  } = result;

  // check current user id
  const userInfo = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (userInfo) {
      trigger({ id: userInfo._id });
    }
  }, []);

  let rowData = [];

  let rowDataTemplate = [
    {
      id: 1,
      title: "日本物業旅居及投資講座2023",
      webURL: "https://vimeo.com/850814849?share=copy",
      completed: false,
    },
    {
      id: 2,
      title: "日本樓變?凶宅如何處理",
      webURL: "https://www.youtube.com/watch?v=dOChYS0Z6DM",
      completed: false,
    },
    {
      id: 3,
      title: "日本物業伏位",
      webURL: "https://www.youtube.com/watch?v=NcdvBb0ohX8",
      completed: false,
    },
    {
      id: 4,
      title: "投資物業須知",
      webURL: "https://www.youtube.com/watch?v=BSbyowtlxuw",
      completed: false,
    },
    {
      id: 5,
      title: "成交前離世怎辦",
      webURL: "https://www.youtube.com/watch?v=hsQfOUiyavsf",
      completed: false,
    },
    {
      id: 6,
      title: "經營簽證的真相",
      webURL: "https://www.youtube.com/watch?v=zcqM1kwawgg",
      completed: false,
    },
    {
      id: 7,
      title: "什麼是重要事項說明",
      webURL: "https://www.youtube.com/watch?v=VAbP0YS-Bqw",
      completed: false,
    },
    {
      id: 8,
      title: "賣日本樓樓須知(1)",
      webURL: "https://www.youtube.com/watch?v=9l9AZ_CRTIQ",
      completed: false,
    },
    {
      id: 9,
      title: "賣日本樓樓須知(2)",
      webURL: "https://www.youtube.com/watch?v=7UxHYrttEP8",
      completed: false,
    },
    {
      id: 10,
      title: "日本一戶建須知",
      webURL: "https://www.youtube.com/watch?v=5dOOePuLLtQ",
      completed: false,
    },
  ];

  useEffect(() => {
    if (trainingStatus && trainingStatus[0]) {
      let rowDataNew = [...rowDataTemplate];

      rowDataNew.forEach((item, index) => {
        let courseKey = `completedCourse${item.id}`;
        if (trainingStatus[0][courseKey] === true) {
          item.completed = true;
        }
      });
      rowData = [...rowDataNew];
    } else {
      rowData = [...rowDataTemplate];
    }

    setRows(rowData);
  }, [trainingStatus]);

  const [rows, setRows] = useState();
  const [rowModesModel, setRowModesModel] = useState({});

  //for snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  //for file modal
  const [updateTrainingStatus, { isLoading: isUpdating }] =
    useUpdateTrainingStatusMutation();

  // *** snackbar *** //
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleProcessRowUpdateError = React.useCallback((error) => {
    // showSnackbar("error", error.message);
    //setSnackbarMessage("Error! - " + error.message);
  }, []);
  const handleFinishedClick = (id) => async () => {
    // update backend
    try {
      let response = {};

      // update the backend
      response = await updateTrainingStatus({
        id: userInfo._id,
        data: { id },
      }).unwrap();
      //get response message
      if (response.message === "Update Done!") {
        // set the snackbar message
        if (response.message === "Update Done!")
          setSnackbarMessage(t("training.snackbar.Record Updated"));

        // show snackbar
        setOpenSnackbar(true);
        // update frontend
        setRows((prevRows) => {
          return prevRows.map((row, index) =>
            index === id - 1 ? { ...row, status: true } : row
          );
        });
      }
    } catch (error) {}
  };

  const inheritLinkStyle = {
    color: "inherit", // Inherit text color
    textDecoration: "inherit", // Inherit text decoration
  };

  const columns = [
    { field: "id", headerName: "No", flex: 0.01, editable: false },
    {
      field: "title",
      headerName: t("training.header.Title"),
      flex: 0.05,
      editable: true,
      type: "text",
    },
    {
      field: "webURL",
      headerName: t("training.header.Description"),
      flex: 0.1,
      editable: false,
      renderCell: (params) => (
        <a href={params.value} target="_blank" style={inheritLinkStyle}>
          Watch This: {params.value}
        </a>
      ),
    },
    {
      field: "completed",
      headerName: t("training.header.Completed"),
      flex: 0.05,
      editable: true,
      type: "text",
      renderCell: (params) => {
        if (params.value === true) {
          return (
            <CheckCircleIcon
              style={{
                color: "#42FF33",
              }}
            />
          );
        } else {
          return (
            <CloseCircleIcon
              style={{
                color: "red",
              }}
            />
          );
        }
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: t("training.header.Actions"),
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title={t("training.menu.Mark as Finished")}>
                <MarkAsFinishedIcon />
              </Tooltip>
            }
            label={t("training.menu.Mark as Finished")}
            className="textPrimary"
            onClick={handleFinishedClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const tableGrid = () => {
    let content;
    content = (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {/** Snackbar */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={1000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            sx={{
              width: "100%",
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <ThemeProvider theme={themeWithLocale}>
            <DataGrid
              loading={isLoadingTrainingStatus || !trainingStatus}
              rows={rows || []}
              columns={columns}
              getRowId={(row) => row.id}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              //   processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              slotProps={{
                toolbar: { setRows, setRowModesModel, rows },
              }}
            />
          </ThemeProvider>
        </Box>
      </LocalizationProvider>
    );
    return content;
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title={t("training.TRAINING")}
        subtitle={t("training.List of Training")}
      />
      {tableGrid()}
    </Box>
  );
};

export default Training;
