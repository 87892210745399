import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Header from "components/Header";
import { useLazyGetCustomerAssetsQuery } from "state/userApi";
import { store } from "app/store";

import { Box, useTheme, Tooltip } from "@mui/material";

import {
  DataGrid,
  GridActionsCellItem,
  GRID_DATE_COL_DEF,
} from "@mui/x-data-grid";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { LocalizationProvider } from "@mui/x-date-pickers";

import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const dateColumnType = {
  ...GRID_DATE_COL_DEF,
  resizable: false,
};

const currencyFormatter = new Intl.NumberFormat("ja-JP", {
  style: "currency",
  currency: "JPY",
});

const MyAssets = () => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState();
  const theme = useTheme();
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  );

  const getCurrentLocale = () => {
    return t("currentLocale");
  };

  const currentLocale = getCurrentLocale();

  // set locale for datagrid
  useEffect(() => {
    if (currentLocale === "zh-Hant") {
      setLocale("zhHK");
    } else {
      setLocale("enUS");
    }
  }, [currentLocale]);
  const navigate = useNavigate();
  const location = useLocation();

  const [rows, setRows] = useState();
  const [rowModesModel, setRowModesModel] = useState({});
  const [currentCustomerId, setCurrentCustomerId] = useState("");
  const [skip, setSkip] = useState(true);

  // get user data from localstorage
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [trigger, result, lastPromiseInfo] = useLazyGetCustomerAssetsQuery();
  const {
    data: customerAssets,
    error: customerAssetsError,
    isError: isCustomAssetsError,
    isLoading: isLoadingCustomerAssets,
    isFetching: isFetchCustomerAssets,
  } = result;
  // trigger({ id: userData["_id"] });

  useEffect(() => {
    setCurrentCustomerId(userData["_id"]);
    setSkip(false);
  }, [userData]);

  useEffect(() => {
    if (!skip) {
      //fetch
      trigger({ id: currentCustomerId });
      setSkip(true);
    }
  }, [currentCustomerId]);

  const handleListTransactionsClick = (id) => () => {
    let selectedRow = rows.find((row) => row.id === id);

    navigate("/myassettransactions", {
      state: { asset_id: selectedRow["_id"] },
    });
  };

  // update the data grid row, if loaded with new data
  useEffect(() => {
    if (!result.isLoading) {
      setRows(customerAssets);
    }
  }, [result.isFetching]);

  const columns = [
    { field: "id", headerName: "No", flex: 0.01, editable: false },
    {
      field: "name",
      headerName: t("myAssets.header.Name"),
      flex: 0.05,
    },
    {
      field: "address",
      headerName: t("myAssets.header.Address"),
      flex: 0.05,
    },
    {
      field: "prefecture",
      headerName: t("myAssets.header.Prefecture"),
      flex: 0.05,
    },
    { field: "city", headerName: t("myAssets.header.City"), flex: 0.05 },
    { field: "subarea", headerName: t("myAssets.header.Subarea"), flex: 0.05 },
    {
      field: "housingType",
      headerName: t("myAssets.header.Housing Type"),
      flex: 0.05,
      type: "singleSelect",
      valueOptions: [
        {
          value: "Apartment",
          label: t("myAssets.select.Apartment"),
        },
        {
          value: "Detached",
          label: t("myAssets.select.Detached"),
        },
        {
          value: "Land",
          label: t("myAssets.select.Land"),
        },
        {
          value: "Whole Building",
          label: t("myAssets.select.Whole Building"),
        },
        {
          value: "Office",
          label: t("myAssets.select.Office"),
        },
      ],
    },
    {
      field: "cost",
      headerName: t("myAssets.header.Cost in Yen"),
      flex: 0.05,
      type: "number",
      valueFormatter: ({ value }) => currencyFormatter.format(value),
    },
    {
      field: "transactionDate",
      ...dateColumnType,
      headerName: t("myAssets.header.Transaction Date"),
      flex: 0.05,
      valueGetter: ({ value }) => new Date(value),
    },

    {
      field: "actions",
      type: "actions",
      headerName: t("myAssets.header.Actions"),
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title={t("myAssets.menu.List Transactions")}>
                <FormatListNumberedIcon />
              </Tooltip>
            }
            label={t("myAssets.menu.List Transactions")}
            className="textPrimary"
            color="inherit"
            onClick={handleListTransactionsClick(id)}
          />,
        ];
      },
    },
  ];

  const tableGrid = () => {
    let content;
    content = (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <ThemeProvider theme={themeWithLocale}>
            <DataGrid
              loading={isLoadingCustomerAssets || !customerAssets}
              rows={rows || []}
              columns={columns}
              getRowId={(row) => row.id}
              rowModesModel={rowModesModel}
            />
          </ThemeProvider>
        </Box>
      </LocalizationProvider>
    );
    return content;
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title={t("myAssets.MY ASSETS")}
        subtitle={t("myAssets.List of My Assets")}
      />

      {tableGrid()}
    </Box>
  );
};

export default MyAssets;
