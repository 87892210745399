import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.userToken;
      console.log("authService - token:", token);
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        return headers;
      }
    },
  }),
  endpoints: (build) => ({
    // getUserDetails: build.query({
    //   query: () => ({
    //     url: "/user/profile",
    //     method: "GET",
    //   }),
    // }),
    patchUserPassword: build.mutation({
      query(data) {
        const { ...body } = data;
        return {
          url: `/user/password/`,
          method: "PATCH",
          body: body,
        };
      },
    }),
  }),
});

// export react hook
// export const { useGetUserDetailsQuery, usePatchUserPasswordMutation } = authApi;
export const { usePatchUserPasswordMutation } = authApi;
