import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import { useGetTransactionsQuery } from "state/api";
import {
  useLazyGetCustomerAssetsQuery,
  useLazyGetCustomerAssetTransactionsQuery,
  useLazyGetCustomerAssetTransactionFilesQuery,
} from "state/userApi";
import { store } from "../../app/store";
import {
  getFileFromUserApi,
  getImageFilesFromUserApi,
} from "../../features/file/fileActions";

import {
  Autocomplete,
  Box,
  CircularProgress,
  useTheme,
  TextField,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Modal,
  Typography,
  Tooltip,
} from "@mui/material";

import DataGridCustomToolbar from "components/DataGridCustomToolbar";

import { createFilterOptions } from "@mui/material/Autocomplete";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  useGridApiContext,
  GRID_DATE_COL_DEF,
} from "@mui/x-data-grid";

import {
  PermMediaOutlined as PermMediaOutlinedIcon,
  PreviewOutlined as PreviewOutlinedIcon,
} from "@mui/icons-material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { formatBytes } from "../../utils/formateBytes";

import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const currencyFormatter = new Intl.NumberFormat("ja-JP", {
  style: "currency",
  currency: "JPY",
});

const dateColumnType = {
  ...GRID_DATE_COL_DEF,
  resizable: false,
};

const MyAssetTransactions = () => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState();
  const theme = useTheme();
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  );

  const getCurrentLocale = () => {
    return t("currentLocale");
  };

  const currentLocale = getCurrentLocale();

  // set locale for datagrid
  useEffect(() => {
    if (currentLocale === "zh-Hant") {
      setLocale("zhHK");
    } else {
      setLocale("enUS");
    }
  }, [currentLocale]);

  const navigate = useNavigate();
  const location = useLocation();

  // get user data from localstorage
  const userData = JSON.parse(localStorage.getItem("userData"));

  const [
    triggerGetCustomerAssets,
    resultCustomerAssets,
    lastPromiseInfoCustomerAssets,
  ] = useLazyGetCustomerAssetsQuery();
  const {
    data: customerAssets,
    error: customerAssetsError,
    isError: isCustomAssetsError,
    isLoading: isLoadingCustomerAssets,
    isFetching: isFetchCustomerAssets,
  } = resultCustomerAssets;

  const [lstAsset, setLstAsset] = useState([]);

  const [optionDefaultValue, setOptionDefaultValue] = useState(null);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [selectedAssetName, setSelectedAssetName] = useState("");

  const [currentCustomerId, setCurrentCustomerId] = useState("");
  const [skipFetchAssets, setSkipFetchAssets] = useState(true);

  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const [skip, setSkip] = useState(true);

  const [rows, setRows] = useState();
  const [rowModesModel, setRowModesModel] = useState({});

  const [previewUrl, setPreviewUrl] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [images, setImages] = useState([]);
  const [isFetchingImage, setIsFetchingImage] = useState(false);

  const [currentMonthNetIncome, setCurrentMonthNetIncome] = useState(0);

  const handleShowPreview = () => {
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  //for file modal
  const [openFileModal, setOpenFileModal] = React.useState(false);
  const handleOpenFileModal = () => setOpenFileModal(true);
  const handleCloseFileModal = () => setOpenFileModal(false);

  const [
    trigger,
    {
      isLoading: isLoadingAssetTransactions,
      isFetching: isFetchAssetTransactions,
      data: assetTransactions,
    },
  ] = useLazyGetCustomerAssetTransactionsQuery();

  const [triggerFiles, { isLoading: isLoadingFiles, data: resultFiles }] =
    useLazyGetCustomerAssetTransactionFilesQuery();

  useEffect(() => {
    setCurrentCustomerId(userData["_id"]);
    setSkipFetchAssets(false);
  }, [userData]);

  useEffect(() => {
    if (!skipFetchAssets) {
      //fetch
      triggerGetCustomerAssets({ id: userData._id });
      setSkipFetchAssets(true);
    }
  }, [currentCustomerId]);
  // file modal
  const fileModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: theme.palette.primary.main,
    border: "0px",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    // update the autocomplete data
    var tmp = [];
    for (var i in customerAssets) {
      tmp.push(customerAssets[i]);
    }

    setLstAsset(tmp);
  }, [customerAssets]);

  // pre-fetch image
  useEffect(() => {
    //reset state
    setIsFetchingImage(true);
    const fetchImages = async () => {
      let resp = {};
      try {
        resp = await getImageFilesFromUserApi(selectedTransactionId);
        setImages(resp.data);
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetchingImage(false);
      }
    };

    if (!isLoadingFiles && resultFiles) {
      fetchImages();
    }
  }, [resultFiles]);

  useEffect(() => {
    // check if there is any location.state data
    try {
      const currentAssetId = location.state.asset_id;
      for (var i in lstAsset) {
        if (lstAsset[i]._id === currentAssetId) {
          setOptionDefaultValue(lstAsset[i]);
          setSelectedAssetName(lstAsset[i].name);
          //setSelectedAssetCustomerId(lstAsset[i].ownedBy);
          break;
        }
      }
      setSelectedAssetId(currentAssetId);
      setSkip(false);
      //clean location state
    } catch (error) {
      //do nothing
    }
  }, [lstAsset]);

  useEffect(() => {
    if (!skip) {
      //fetch
      trigger({ id: selectedAssetId });
    }
  }, [selectedAssetId]);

  useEffect(() => {
    if (!skip) {
      //fetch
      triggerFiles({ id: selectedTransactionId });
    }
  }, [selectedTransactionId]);

  const calculateCurrentMonthNetIncome = () => {
    if (assetTransactions) {
      //get current yearmonth
      const currentDate = new Date();
      let year = currentDate.getFullYear();
      let month = currentDate.getMonth() + 1;
      let currentYearMonth = year * 100 + month;

      // get net income of current month
      let currMonthNetIncome = assetTransactions
        .filter((item) => item.transactionYearMonth === currentYearMonth)
        .reduce(function (acc, obj) {
          return (
            acc + (obj.transactionType === "credit" ? +1 : -1) * obj.amount
          );
        }, 0);

      setCurrentMonthNetIncome(currMonthNetIncome);
    }
  };

  // update the data grid row, if loaded with new data
  useEffect(() => {
    if (!isLoadingAssetTransactions) {
      setRows(assetTransactions);

      calculateCurrentMonthNetIncome();
    }
  }, [isFetchAssetTransactions]);

  const handleSelectedAsset = async (value) => {
    setOptionDefaultValue(value);
    if (value) {
      // get the related assets
      let response = {};
      try {
        setSelectedAssetId(value._id);
        setSelectedAssetName(value.name);

        setSkip(false);
      } catch (error) {
        console.log("error", error.message);
      }
    } else {
      setSelectedAssetId(null);
      setSelectedAssetName("");

      setSkip(true);
    }
  };

  const handlePreviewFileClick = (id) => async () => {
    try {
      let resp = {};
      resp = await getFileFromUserApi(id, selectedTransactionId);
      // get the file type
      const contentType = resp.headers["content-type"];
      // Check the file type and set the preview URL accordingly
      if (contentType.startsWith("image/")) {
        const imageUrl = URL.createObjectURL(new Blob([resp.data]));
        setPreviewUrl(imageUrl);
        handleShowPreview();
      } else {
        // For documents or videos, open a new tab for previewing/downloading
        window.open(
          URL.createObjectURL(new Blob([resp.data], { type: contentType }))
        );
      }
    } catch (error) {
      console.log(error);
      console.log("error", error.message);
    }
  };

  const handleViewFilesClick = (id) => () => {
    let selectedRow = rows.find((row) => row.id === id);
    // update the selected transaction id

    setSelectedTransactionId(selectedRow["_id"]);
    //show attache file modal
    handleOpenFileModal();
  };

  const renderImageHeader = () => {
    // check how many image files
    let content = <></>;
    if (!isLoadingFiles && resultFiles) {
      const imageFiles = resultFiles.filter((e) =>
        e.mimetype.startsWith("image")
      );
      if (imageFiles.length > 0) {
        content = (
          <Box>
            <h4>
              {t("myAssetTransactions.modal.List of image files")} (
              {t("myAssetTransactions.modal.Total")}: {imageFiles.length}{" "}
              {t("myAssetTransactions.modal.files")})
            </h4>
          </Box>
        );
      } else {
        content = <></>;
      }
    }
    return content;
  };

  const renderListHeader = () => {
    let content = <></>;
    if (!isLoadingFiles && resultFiles) {
      const nonImageFiles = resultFiles.filter(
        (e) => !e.mimetype.startsWith("image")
      );
      if (nonImageFiles.length > 0) {
        content = (
          <Box>
            <h4>
              {t("myAssetTransactions.modal.List of non-image files")}: (
              {t("myAssetTransactions.modal.Total")} {nonImageFiles.length}{" "}
              {t("myAssetTransactions.modal.files")})
            </h4>
          </Box>
        );
      }
    }
    return content;
  };

  const renderFileListImage = () => {
    // get the info from resultFiles
    const imageFiles =
      resultFiles && resultFiles.filter((e) => e.mimetype.startsWith("image"));

    // get the images from state
    let renderListItem =
      imageFiles && imageFiles.length == 0 ? (
        <></>
      ) : isFetchingImage ? (
        <Box
          m="1rem 0rem 1rem 0rem"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : Array.isArray(images) && images.length > 0 ? (
        images.map((image, index) => (
          <Box key={index} m="0rem 0rem 1rem 0rem">
            <Typography>{`${index + 1}. ${image.originalname}`}</Typography>
            <img src={`data:${image.mimetype};base64,${image.base64Content}`} />
          </Box>
        ))
      ) : (
        <Box m="0rem 0rem 1rem 0rem">
          <CircularProgress />
        </Box>
      );

    let content = <Box>{renderListItem}</Box>;

    return content;
  };

  const renderFileListNonImage = () => {
    let renderListItem = <></>;
    if (!isLoadingFiles && resultFiles) {
      renderListItem = resultFiles
        .filter((e) => !e.mimetype.startsWith("image"))
        .map((e, idx) => (
          <ListItem key={e._id}>
            <ListItemText
              primary={`${idx + 1}. ${e.originalname}`}
              secondary={`(File size: ${formatBytes(e.size)} bytes)`}
            />
            <ListItemIcon>
              <IconButton
                edge="end"
                aria-label="Preview"
                onClick={handlePreviewFileClick(e._id)}
              >
                <Tooltip title={t("myAssetTransactions.modal.preview")}>
                  <PreviewOutlinedIcon />
                </Tooltip>
              </IconButton>
            </ListItemIcon>
          </ListItem>
        ));
    }

    const content = (
      <List style={{ minWidth: 100, maxHeight: 200, overflow: "scroll" }}>
        {renderListItem}
      </List>
    );
    return content;
  };

  const filterOptions = createFilterOptions({
    limit: 500, // only show 500 entries, the rest need to be search
  });

  const columns = [
    { field: "id", headerName: "No", flex: 0.01, editable: false },
    {
      field: "transactionType",
      headerName: t("myAssetTransactions.header.Transaction Type"),
      flex: 0.05,
      type: "singleSelect",
      valueOptions: [
        { value: "credit", label: "收入" },
        { value: "debit", label: "支出" },
      ],
    },
    {
      field: "transactionDetail",
      headerName: t("myAssetTransactions.header.Transaction Detail"),
      flex: 0.05,
      type: "singleSelect",
      valueOptions: [
        {
          value: "Property Custody Fee",
          label: t("myAssetTransactions.select.Property Custody Fee"),
        },
        { value: "Rental", label: t("myAssetTransactions.select.Rental") },
        {
          value: "Property Management Fee",
          label: t("myAssetTransactions.select.Property Management Fee"),
        },
        {
          value: "Building Maintenance Fee",
          label: t("myAssetTransactions.select.Building Maintenance Fee"),
        },
        {
          value: "Fire Insurance",
          label: t("myAssetTransactions.select.Fire Insurance"),
        },
        {
          value: "Advertising Fee for Lease",
          label: t("myAssetTransactions.select.Advertising Fee for Rent"),
        },
        {
          value: "Bank Transfer Fee",
          label: t("myAssetTransactions.select.Bank Transfer Fee"),
        },
        {
          value: "Other Income",
          label: t("myAssetTransactions.select.Other Income"),
        },
        {
          value: "Other Expense",
          label: t("myAssetTransactions.select.Other Expense"),
        },
      ],
    },
    {
      field: "remark",
      headerName: t("myAssetTransactions.header.Remark"),
      flex: 0.05,
      type: "text",
    },
    {
      field: "amount",
      headerName: t("myAssetTransactions.header.Amount in YEN"),
      flex: 0.05,
      type: "number",
      valueFormatter: ({ value }) => currencyFormatter.format(value),
    },
    {
      field: "transactionDate",
      ...dateColumnType,
      headerName: t("myAssetTransactions.header.Transaction Date"),
      flex: 0.05,
      valueGetter: ({ value }) => new Date(value),
    },
    // {
    //   field: "numOfFiles",
    //   headerName: t("myAssetTransactions.header.Num of Attached Files"),
    //   flex: 0.05,
    // },
    {
      field: "actions",
      type: "actions",
      headerName: t("myAssetTransactions.header.Actions"),
      width: 150,
      cellClassName: "actions",
      getActions: ({ id, ...props }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        const actions = [];

        const numOfFiles = props.row.numOfFiles;
        if (numOfFiles > 0) {
          actions.push(
            <GridActionsCellItem
              icon={
                <Tooltip title={t("myAssetTransactions.menu.View Files")}>
                  <PermMediaOutlinedIcon />
                </Tooltip>
              }
              label={t("myAssetTransactions.menu.View Files")}
              className="textPrimary"
              color="inherit"
              onClick={handleViewFilesClick(id)}
            />
          );
        }

        return actions;
      },
    },
  ];

  const tableGrid = () => {
    let content;
    content = (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box>
          <b>{t("myAssetTransactions.Name")}</b>
          {selectedAssetName}
        </Box>
        <Box m="1.5rem 1.5rem 0rem 0rem">
          <b>{t("myAssetTransactions.Current Month Net Income")}</b>
          {currencyFormatter.format(currentMonthNetIncome)}
        </Box>
        <Modal
          open={openFileModal}
          onClose={handleCloseFileModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ overflow: "scroll" }}
        >
          <Box sx={fileModalStyle}>
            <Typography id="modal-modal-title" variant="h4" component="h2">
              {t("myAssetTransactions.modal.View Files")}
            </Typography>
            {renderImageHeader()}
            <FlexBetween m="1.5rem 0rem 1.5rem 0rem">
              <Box
                sx={{
                  backgroundColor: theme.palette.primary[300],
                  color: theme.palette.grey[1],
                  overflowY: "auto",
                  display: "flex",
                  flexGrow: 1,
                  flexDirection: "column",
                  maxHeight: "200px",
                  halign: "center",
                }}
              >
                {renderFileListImage()}
              </Box>
            </FlexBetween>
            {renderListHeader()}
            <FlexBetween m="1.5rem 0rem 1.5rem 0rem">
              <Box
                sx={{
                  backgroundColor: theme.palette.primary[300],
                  color: theme.palette.grey[1],
                  overflowY: "auto",
                  display: "flex",
                  flexGrow: 1,
                  flexDirection: "column",
                  maxHeight: "200px",
                  halign: "center",
                }}
              >
                {renderFileListNonImage()}
              </Box>
              {isLoadingFiles && (
                <Box>
                  <h4>Loading ...</h4>
                </Box>
              )}
            </FlexBetween>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
            ></Typography>
          </Box>
        </Modal>
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <ThemeProvider theme={themeWithLocale}>
            <DataGrid
              loading={isLoadingAssetTransactions || !assetTransactions}
              rows={rows || []}
              columns={columns}
              getRowId={(row) => row.id}
              editMode="row"
              rowModesModel={rowModesModel}
            />
          </ThemeProvider>
        </Box>
      </LocalizationProvider>
    );
    return content;
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title={t("myAssetTransactions.MY ASSET TRANSACTIONS")}
        subtitle={t("myAssetTransactions.List of my asset transactions")}
      />
      <Box m="1.5rem 0rem">
        <Autocomplete
          filterOptions={filterOptions}
          loading={isLoadingCustomerAssets || !assetTransactions || !lstAsset}
          onChange={(event, value) => handleSelectedAsset(value)} // prints the selected value
          id="select-asset"
          sx={{ width: 300 }}
          options={lstAsset}
          autoHighlight
          getOptionLabel={(option) => (option.id > 0 ? option.name : "")}
          value={optionDefaultValue || null}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
              key={option.id}
            >
              {option.name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("myAssetTransactions.Choose an asset")}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Box>
      {!skip && tableGrid()}
    </Box>
  );
};

export default MyAssetTransactions;
