import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Header from "components/Header";
import {
  useGetCustomersQuery,
  useLazyGetCustomerAssetsFromAdminQuery,
  useUpdateCustomerAssetFromAdminMutation,
  useAddCustomerAssetFromAdminMutation,
  useDeleteCustomerAssetFromAdminMutation,
} from "state/api";

import {
  Alert as MuiAlert,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  useTheme,
  TextField,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  useGridApiContext,
  GRID_DATE_COL_DEF,
} from "@mui/x-data-grid";
import {
  Add as AddIcon,
  Save as SaveIcon,
  Close as CancelIcon,
  Edit as EditIcon,
  DeleteOutlined as DeleteIcon,
  FormatListNumbered as FormatListNumberedIcon,
  Security as SecurityIcon,
} from "@mui/icons-material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { createFilterOptions } from "@mui/material/Autocomplete";

import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function GridEditDateCell({ id, field, value }) {
  const apiRef = useGridApiContext();

  function handleChange(newValue) {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  }

  return (
    <DatePicker
      value={value}
      renderInput={(params) => <TextField {...params} />}
      onChange={handleChange}
    />
  );
}

function EditToolbar(props) {
  const { t, i18n } = useTranslation();
  const { setRows, setRowModesModel, rows } = props;

  const handleAddRecordClick = () => {
    //get the row id

    const newId =
      rows.length === 0
        ? 1
        : Math.max(...rows.map((r) => (r.id ? r.id : 0) * 1)) + 1;

    const id = newId;
    setRows((oldRows) => [
      ...oldRows,
      {
        id: newId,
        name: "",
        address: "",
        prefecture: "",
        city: "",
        subarea: "",
        housingType: "",
        cost: 0,
        transactionDate: "",
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };
  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleAddRecordClick}
      >
        {t("assets.Add Asset")}
      </Button>
    </GridToolbarContainer>
  );
}
const currencyFormatter = new Intl.NumberFormat("ja-JP", {
  style: "currency",
  currency: "JPY",
});

const dateColumnType = {
  ...GRID_DATE_COL_DEF,
  resizable: false,
  renderEditCell: (params) => <GridEditDateCell {...params} />,
};

const Assets = () => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState();
  const theme = useTheme();
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  );

  const getCurrentLocale = () => {
    return t("currentLocale");
  };

  const currentLocale = getCurrentLocale();

  // set locale for datagrid
  useEffect(() => {
    if (currentLocale === "zh-Hant") {
      setLocale("zhHK");
    } else {
      setLocale("enUS");
    }
  }, [currentLocale]);

  const navigate = useNavigate();
  const location = useLocation();

  const { data, isLoading } = useGetCustomersQuery(); // get customer data
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [skip, setSkip] = useState(true);

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const [trigger, result, lastPromiseInfo] =
    useLazyGetCustomerAssetsFromAdminQuery();
  const {
    data: customerAssets,
    error: customerAssetsError,
    isError: isCustomAssetsError,
    isLoading: isLoadingCustomerAssets,
    isFetching: isFetchCustomerAssets,
  } = result;

  const [lstCustomer, setLstCustomer] = useState([]);

  const [optionDefaultValue, setOptionDefaultValue] = useState(null);

  const [rows, setRows] = useState();
  const [rowModesModel, setRowModesModel] = useState({});

  //for snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [updateCustomerAsset, { isLoading: isUpdating }] =
    useUpdateCustomerAssetFromAdminMutation();

  const [addCustomerAsset, { isLoading: isAdding }] =
    useAddCustomerAssetFromAdminMutation();

  const [deleteCustomerAsset, { isLoading: isDeletting }] =
    useDeleteCustomerAssetFromAdminMutation();

  // *** snackbar *** //
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  // *** confirm delete dialog *** //
  const handleCloseConfirmDeleteDialog = () => {
    setDeleteId("");
    setOpenConfirmDeleteDialog(false);
  };

  const handleSubmitConfirmDeleteDialog = async () => {
    let selectedRow = rows.find((row) => row.id === deleteId);
    // call api to delete the administrator
    try {
      let response = {};
      //updating
      response = await deleteCustomerAsset({
        id: selectedRow["_id"], // actual id
      }).unwrap();

      // get message
      if (response.message === "Delete Done!") {
        // set the snackbar message
        setSnackbarMessage(t("assets.snackbar.Record Deleted"));

        // show snackbar
        setOpenSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage("Error: " + error);
      // show snackbar
      setOpenSnackbar(true);
    }
    //update the display
    setRows(rows.filter((row) => row.id !== deleteId));
    //reset
    setDeleteId("");
    setOpenConfirmDeleteDialog(false);
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleDeleteClick = (id) => () => {
    //setRows(rows.filter((row) => row.id !== id));
    // save the row id
    setDeleteId(id);
    setOpenConfirmDeleteDialog(true);
  };

  const handleListTransactionsClick = (id) => () => {
    let selectedRow = rows.find((row) => row.id === id);

    navigate("/assettransactions", {
      state: { asset_id: selectedRow["_id"] },
    });
  };

  // update the data grid row, if loaded with new data
  useEffect(() => {
    if (!result.isLoading) {
      setRows(customerAssets);
    }
  }, [result.isFetching]);

  useEffect(() => {
    if (!skip) {
      //fetch
      trigger({ id: selectedCustomerId });
    }
  }, [selectedCustomerId]);

  useEffect(() => {
    // update the autocomplete data
    var tmp = [];
    for (var i in data) tmp.push(data[i]);
    setLstCustomer(tmp);
  }, [isLoading]);

  useEffect(() => {
    // check if there is any location.state data
    try {
      const currentUserId = location.state.user_id;

      for (var i in lstCustomer) {
        if (lstCustomer[i]._id === currentUserId) {
          setOptionDefaultValue(lstCustomer[i]);
          break;
        }
      }
      setSelectedCustomerId(currentUserId);
      setSkip(false);
      //clean location state
    } catch (error) {
      //do nothing
    }
  }, [lstCustomer]);

  const columns = [
    { field: "id", headerName: "No", flex: 0.01, editable: false },
    {
      field: "name",
      headerName: t("assets.header.Name"),
      flex: 0.05,
      editable: true,
    },
    {
      field: "address",
      headerName: t("assets.header.Address"),
      flex: 0.05,
      editable: true,
    },
    {
      field: "prefecture",
      headerName: t("assets.header.Prefecture"),
      type: "singleSelect",
      flex: 0.05,
      editable: true,
      valueOptions: [
        "Aichi - 愛知県",
        "Akita - 秋田県",
        "Aomori - 青森県",
        "Chiba - 千葉県",
        "Ehime - 愛媛県",
        "Fukui - 福井県",
        "Fukuoka - 福岡県",
        "Fukushima - 福島県",
        "Gifu - 岐阜県",
        "Gunma - 群馬県",
        "Hiroshima - 広島県",
        "Hokkaido - 北海道",
        "Hyogo - 兵庫県",
        "Ibaraki - 茨城県",
        "Ishikawa - 石川県",
        "Iwate - 岩手県",
        "Kagawa - 香川県",
        "Kagoshima - 鹿児島県",
        "Kanagawa - 神奈川県",
        "Kochi - 高知県",
        "Kumamoto - 熊本県",
        "Kyoto - 京都府",
        "Mie - 三重県",
        "Miyagi - 宮城県",
        "Miyazaki - 宮崎県",
        "Nagano - 長野県",
        "Nagasaki - 長崎県",
        "Nara - 奈良県",
        "Niigata - 新潟県",
        "Oita - 大分県",
        "Okayama - 岡山県",
        "Okinawa - 沖縄県",
        "Osaka - 大阪府",
        "Saga - 佐賀県",
        "Saitama - 埼玉県",
        "Shiga - 滋賀県",
        "Shimane - 島根県",
        "Shizuoka - 静岡県",
        "Tochigi - 栃木県",
        "Tokushima - 徳島県",
        "Tokyo - 東京都",
        "Tottori - 鳥取県",
        "Toyama - 富山県",
        "Wakayama - 和歌山県",
        "Yamagata - 山形県",
        "Yamaguchi - 山口県",
        "Yamanashi - 山梨県",
      ],
    },
    {
      field: "city",
      headerName: t("assets.header.City"),
      flex: 0.05,
      editable: true,
    },
    {
      field: "subarea",
      headerName: t("assets.header.Subarea"),
      flex: 0.05,
      editable: true,
    },
    {
      field: "housingType",
      headerName: t("assets.header.Housing Type"),
      flex: 0.05,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        {
          value: "Apartment",
          label: t("assets.select.Apartment"),
        },
        {
          value: "Detached",
          label: t("assets.select.Detached"),
        },
        {
          value: "Land",
          label: t("assets.select.Land"),
        },
        {
          value: "Whole Building",
          label: t("assets.select.Whole Building"),
        },
        {
          value: "Office",
          label: t("assets.select.Office"),
        },
      ],
    },
    {
      field: "cost",
      headerName: t("assets.header.Cost in Yen"),
      flex: 0.05,
      editable: true,
      type: "number",
      valueFormatter: ({ value }) => currencyFormatter.format(value),
    },
    {
      field: "transactionDate",
      ...dateColumnType,
      headerName: t("assets.header.Transaction Date"),
      flex: 0.05,
      editable: true,
      valueGetter: ({ value }) => new Date(value),
    },

    {
      field: "actions",
      type: "actions",
      headerName: t("assets.header.Actions"),
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode && !isUpdating && !isAdding) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        if (isUpdating || isAdding) {
          return [
            <>
              <CircularProgress size="1.5rem" />
            </>,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <Tooltip title={t("assets.menu.Edit Asset")}>
                <EditIcon />
              </Tooltip>
            }
            label={t("assets.menu.Edit Asset")}
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title={t("assets.menu.List Transactions")}>
                <FormatListNumberedIcon />
              </Tooltip>
            }
            label={t("assets.menu.List Transactions")}
            className="textPrimary"
            color="inherit"
            onClick={handleListTransactionsClick(id)}
          />,

          <GridActionsCellItem
            icon={<DeleteIcon />}
            label={t("assets.menu.Delete Asset")}
            onClick={handleDeleteClick(id)}
            showInMenu
          />,
        ];
      },
    },
  ];

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleProcessRowUpdateError = React.useCallback((error) => {
    // showSnackbar("error", error.message);
    //setSnackbarMessage("Error! - " + error.message);
  }, []);
  const processRowUpdate = async (newRow) => {
    try {
      let response = {};
      if (newRow["isNew"]) {
        //insert new record
        response = await addCustomerAsset({
          id: selectedCustomerId,
          data: newRow,
        }).unwrap();

        //get _id
        const new_id = response._id; //asset _id
        newRow["_id"] = new_id;
      } else {
        //updating
        response = await updateCustomerAsset({
          id: newRow["_id"],
          data: newRow,
        }).unwrap();
      }

      //get response message
      if (
        response.message === "Update Done!" ||
        response.message === "Add Done!" ||
        response.message === "Delete Done!"
      ) {
        // set the snackbar message
        if (response.message === "Update Done!")
          setSnackbarMessage(t("assets.snackbar.Record Updated"));
        else if (response.message === "Add Done!")
          setSnackbarMessage(t("assets.snackbar.Record Added"));
        else if (response.message === "Delete Done!")
          setSnackbarMessage(t("assets.snackbar.Record Deleted"));
        // show snackbar
        setOpenSnackbar(true);
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
      }
    } catch (error) {
      return null;
    }
  };

  const handleSelectedCustomer = async (value) => {
    setOptionDefaultValue(value);
    if (value) {
      // get the related assets
      let response = {};
      try {
        setSelectedCustomerId(value._id);
        setSkip(false);
      } catch (error) {
        setSnackbarMessage("Error: " + error);
        // show snackbar
        setOpenSnackbar(true);
      }
    } else {
      setSelectedCustomerId(null);
      setSkip(true);
    }
  };

  const filterOptions = createFilterOptions({
    limit: 500, // only show 500 entries, the rest need to be search
  });

  const tableGrid = () => {
    let content;
    content = (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {/** Confirm Dialog */}
        <Dialog
          open={openConfirmDeleteDialog}
          onClose={handleCloseConfirmDeleteDialog}
        >
          <DialogContent
            sx={{
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
            }}
          >
            <DialogContentText>
              {t("assets.dialog.Confirm Delete")}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
            }}
          >
            <Button
              onClick={handleCloseConfirmDeleteDialog}
              sx={{
                backgroundColor: theme.palette.background.alt,
                color: `${theme.palette.secondary[100]} !important`,
              }}
            >
              {t("assets.dialog.CANCEL")}
            </Button>
            <Button
              onClick={handleSubmitConfirmDeleteDialog}
              sx={{
                backgroundColor: theme.palette.background.alt,
                color: `${theme.palette.secondary[100]} !important`,
              }}
            >
              {t("assets.dialog.DELETE")}
            </Button>
          </DialogActions>
        </Dialog>
        {/** Snackbar */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={1000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            sx={{
              width: "100%",
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <ThemeProvider theme={themeWithLocale}>
            <DataGrid
              loading={isLoadingCustomerAssets || !customerAssets}
              rows={rows || []}
              columns={columns}
              getRowId={(row) => row.id}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              slots={{
                toolbar: EditToolbar,
              }}
              slotProps={{
                toolbar: { setRows, setRowModesModel, rows },
              }}
            />
          </ThemeProvider>
        </Box>
      </LocalizationProvider>
    );
    return content;
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title={t("assets.ASSETS")}
        subtitle={t("assets.List of Assets")}
      />
      <Box m="1.5rem 0rem">
        <Autocomplete
          filterOptions={filterOptions}
          //loading={isLoading || !customerAssets || !lstCustomer}
          loading={isLoading || isLoadingCustomerAssets || !lstCustomer}
          onChange={(event, value) => handleSelectedCustomer(value)} // prints the selected value
          id="select-customer"
          sx={{ width: 300 }}
          options={lstCustomer}
          autoHighlight
          getOptionLabel={(option) =>
            option.id > 0
              ? option.lastName +
                " " +
                option.firstName +
                " (" +
                option.phone +
                ")"
              : ""
          }
          value={optionDefaultValue || null}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
              key={option.id}
            >
              {option.lastName} {option.firstName} {"("}
              {option.phone} {")"}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("assets.Choose a customer")}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Box>

      {!skip && tableGrid()}
    </Box>
  );
};

export default Assets;
