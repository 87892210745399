import React, { useEffect, useMemo, useState, useCallback } from "react";

import Header from "components/Header";
import {
  useGetAdministratorsQuery,
  useUpdateAdministratorMutation,
  useAddAdministratorMutation,
  useResetAdministratorPasswordMutation,
  useDeleteAdministratorMutation,
} from "state/api";
import { generatePassword } from "../../utils/generatePassword";

import {
  Alert as MuiAlert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Snackbar,
  Tooltip,
  TextField,
  useTheme,
  Typography,
} from "@mui/material";

import {
  Add as AddIcon,
  Edit as EditIcon,
  DeleteOutlined as DeleteIcon,
  Save as SaveIcon,
  Close as CancelIcon,
  PasswordOutlined as ResetPasswordIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CloseCircleIcon,
} from "@mui/icons-material";

import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GRID_DATE_COL_DEF,
  useGridApiContext,
} from "@mui/x-data-grid";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";

function EditToolbar(props) {
  const { t, i18n } = useTranslation();
  const { setRows, setRowModesModel, rows } = props;

  const handleAddRecordClick = () => {
    //get the row id

    const newId =
      rows.length === 0
        ? 1
        : Math.max(...rows.map((r) => (r.id ? r.id : 0) * 1)) + 1;

    const id = newId;
    setRows((oldRows) => [
      ...oldRows,
      {
        id: newId,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        role: "admin",
        canAccess: true,
        position: "",
        joinDate: "",
        endDate: "",
        remark: "",
        remark2: "",
        remark3: "",
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "firstName" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleAddRecordClick}
      >
        {t("administrators.Add Administrator")}
      </Button>
    </GridToolbarContainer>
  );
}
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function GridEditDateCell({ id, field, value }) {
  const apiRef = useGridApiContext();

  function handleChange(newValue) {
    apiRef.current.setEditCellValue({ id, field, value: newValue });
  }

  return (
    <DatePicker
      value={value}
      renderInput={(params) => <TextField {...params} />}
      onChange={handleChange}
    />
  );
}

const dateColumnType = {
  ...GRID_DATE_COL_DEF,
  resizable: false,
  renderEditCell: (params) => <GridEditDateCell {...params} />,
};

const Administrators = () => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState();
  const theme = useTheme();
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  );

  const getCurrentLocale = () => {
    return t("currentLocale");
  };

  const currentLocale = getCurrentLocale();

  // set locale for datagrid
  useEffect(() => {
    if (currentLocale === "zh-Hant") {
      setLocale("zhHK");
    } else {
      setLocale("enUS");
    }
  }, [currentLocale]);
  const { data, isLoading } = useGetAdministratorsQuery(); // get user role data
  const [rows, setRows] = useState();
  const [rowModesModel, setRowModesModel] = useState({});

  //for snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // for reset password form
  const [openResetPasswordForm, setOpenResetPasswordForm] = useState(false);
  const [resetId, setResetId] = useState("");
  const [resetPassword, setResetPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  // for delete confirm dialog
  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  // *** API *** //
  const [updateAdministrator, { isLoading: isUpdating }] =
    useUpdateAdministratorMutation();

  const [addAdministrator, { isLoading: isAdding }] =
    useAddAdministratorMutation();

  const [resetAdministratorPassword, { isLoading: isResetting }] =
    useResetAdministratorPasswordMutation();

  const [deleteAdministrator, { isLoading: isDeletting }] =
    useDeleteAdministratorMutation();

  useEffect(() => {
    setRows(data);
  }, [isLoading]);

  // *** snackbar *** //
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  // *** generate password ***//
  const handleGeneratePassword = () => {
    const generatedPW = generatePassword(8);
    //set reset password
    setResetPassword(generatedPW);
  };

  // *** confirm delete dialog *** //
  const handleCloseConfirmDeleteDialog = () => {
    setDeleteId("");
    setOpenConfirmDeleteDialog(false);
  };

  const handleSubmitConfirmDeleteDialog = async () => {
    let selectedRow = rows.find((row) => row.id === deleteId);
    // call api to delete the administrator
    try {
      let response = {};
      //updating
      response = await deleteAdministrator({
        id: selectedRow["_id"], // actual id
      }).unwrap();

      // get message
      if (response.message === "Delete Done!") {
        // set the snackbar message
        setSnackbarMessage(t("administrators.snackbar.Record Deleted"));

        // show snackbar
        setOpenSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage("Error: " + error);
      setOpenSnackbar(true);
    }
    //update the display
    setRows(rows.filter((row) => row.id !== deleteId));
    //reset
    setDeleteId("");
    setOpenConfirmDeleteDialog(false);
  };

  // *** reset password form *** //
  const handleCloseResetPasswordForm = () => {
    setResetId("");
    setResetPassword("");
    setOpenResetPasswordForm(false);
  };
  const handleSubmitResetPasswordForm = async (e) => {
    e.preventDefault();
    //checking
    if (resetPassword.length < 8) {
      setErrorMessage(
        t("customers.dialog.Password length should be at least 8 characters")
      );
      return;
    }

    let selectedRow = rows.find((row) => row.id === resetId);
    // let resetPW = resetPassword;
    // call api to save the data
    try {
      let response = {};
      //updating
      response = await resetAdministratorPassword({
        id: selectedRow["_id"], // actual id
        data: { password: resetPassword },
      }).unwrap();

      // get message
      if (response.message === "Update Done!") {
        // set the snackbar message
        setSnackbarMessage(t("administrators.snackbar.Record Updated"));

        // show snackbar
        setOpenSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage("Error: " + error);
      setOpenSnackbar(true);
    }

    setOpenResetPasswordForm(false);
    setResetId("");
    setResetPassword("");
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handlePasswordFieldChange = (e) => {
    setResetPassword(e.target.value);
    if (errorMessage) setErrorMessage(null);
  };

  const handleResetPasswordClick = (id) => () => {
    //show a popup to enter the information
    setResetId(id);
    setOpenResetPasswordForm(true);
  };

  const handleDeleteClick = (id) => () => {
    //setRows(rows.filter((row) => row.id !== id));
    // save the row id
    setDeleteId(id);
    setOpenConfirmDeleteDialog(true);
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleProcessRowUpdateError = React.useCallback((error) => {
    // showSnackbar("error", error.message);
    setSnackbarMessage("Error! - " + error.message);
  }, []);

  const processRowUpdate = async (newRow) => {
    try {
      let response = {};
      if (newRow["isNew"]) {
        //insert new record
        response = await addAdministrator({
          data: newRow,
        }).unwrap();

        //get _id
        const new_id = response._id; //administrator _id
        newRow["_id"] = new_id;
      } else {
        //updating
        response = await updateAdministrator({
          id: newRow["_id"],
          data: newRow,
        }).unwrap();
      }

      // get message
      if (
        response.message === "Update Done!" ||
        response.message === "Add Done!" ||
        response.message === "Delete Done!"
      ) {
        // set the snackbar message
        if (response.message === "Update Done!")
          setSnackbarMessage(t("administrators.snackbar.Record Updated"));
        else if (response.message === "Add Done!")
          setSnackbarMessage(t("administrators.snackbar.Record Added"));
        else if (response.message === "Delete Done!")
          setSnackbarMessage(t("administrators.snackbar.Record Deleted"));
        // show snackbar
        setOpenSnackbar(true);
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
      }
    } catch (error) {
      return null;
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    { field: "id", headerName: "No", flex: 0.01, editable: false },
    {
      field: "lastName",
      headerName: t("administrators.header.Last Name"),
      flex: 0.05,
      editable: true,
    },
    {
      field: "firstName",
      headerName: t("administrators.header.First Name"),
      flex: 0.05,
      editable: true,
    },

    {
      field: "email",
      headerName: t("administrators.header.Email"),
      flex: 0.05,
      editable: true,
    },
    {
      field: "phone",
      headerName: t("administrators.header.Phone"),
      flex: 0.05,
      editable: true,
    },
    {
      field: "position",
      headerName: t("administrators.header.Position"),
      flex: 0.05,
      editable: true,
    },
    {
      field: "joinDate",
      ...dateColumnType,
      headerName: t("administrators.header.Join Date"),
      flex: 0.05,
      editable: true,
      valueGetter: ({ value }) => {
        if (value && value != "") return new Date(value);
        else return null;
      },
    },
    {
      field: "endDate",
      ...dateColumnType,
      headerName: t("administrators.header.End Date"),
      flex: 0.05,
      editable: true,
      valueGetter: ({ value }) => {
        if (value && value != "") return new Date(value);
        else return null;
      },
    },
    {
      field: "canAccess",
      headerName: t("administrators.header.Access"),
      flex: 0.05,
      type: "singleSelect",
      editable: true,
      valueOptions: [
        { value: true, label: "✔" },
        { value: false, label: "✘" },
      ],
      renderCell: (params) => {
        if (params.value === true) {
          return (
            <CheckCircleIcon
              style={{
                color: "#42FF33",
              }}
            />
          );
        } else {
          return (
            <CloseCircleIcon
              style={{
                color: "red",
              }}
            />
          );
        }
      },
    },
    {
      field: "remark",
      headerName: t("administrators.header.Remark"),
      flex: 0.05,
      editable: true,
    },
    {
      field: "remark2",
      headerName: t("administrators.header.Remark2"),
      flex: 0.05,
      editable: true,
    },
    {
      field: "remark3",
      headerName: t("administrators.header.Remark3"),
      flex: 0.05,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: t("administrators.header.Actions"),
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode && !isUpdating && !isAdding && !isResetting) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        if (isUpdating || isAdding || isResetting) {
          return [
            <>
              <CircularProgress size="1.5rem" />
            </>,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <Tooltip title={t("administrators.menu.Edit Administrator")}>
                <EditIcon />
              </Tooltip>
            }
            label={t("administrators.menu.Edit Administrator")}
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,

          <GridActionsCellItem
            icon={
              <Tooltip title={t("administrators.menu.Reset Password")}>
                <ResetPasswordIcon />
              </Tooltip>
            }
            label={t("administrators.menu.Reset Password")}
            onClick={handleResetPasswordClick(id)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label={t("administrators.menu.Delete Administrator")}
            onClick={handleDeleteClick(id)}
            showInMenu
          />,
        ];
      },
    },
  ];

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title={t("administrators.ADMINISTRATORS")}
        subtitle={t("administrators.List of Administrators")}
      />
      {/** reset password form */}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box>
          {/** Confirm Dialog */}
          <Dialog
            open={openConfirmDeleteDialog}
            onClose={handleCloseConfirmDeleteDialog}
          >
            <DialogContent
              sx={{
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
              }}
            >
              <DialogContentText>
                {t("administrators.dialog.Confirm Delete")}
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                backgroundColor: theme.palette.background.alt,
                color: theme.palette.secondary[100],
              }}
            >
              <Button
                onClick={handleCloseConfirmDeleteDialog}
                sx={{
                  backgroundColor: theme.palette.background.alt,
                  color: `${theme.palette.secondary[100]} !important`,
                }}
              >
                {t("administrators.dialog.CANCEL")}
              </Button>
              <Button
                onClick={handleSubmitConfirmDeleteDialog}
                sx={{
                  backgroundColor: theme.palette.background.alt,
                  color: `${theme.palette.secondary[100]} !important`,
                }}
              >
                {t("administrators.dialog.DELETE")}
              </Button>
            </DialogActions>
          </Dialog>

          {/** Reset Password Dialog */}
          <Dialog
            open={openResetPasswordForm}
            onClose={handleCloseResetPasswordForm}
          >
            <form id="reset_password" onSubmit={handleSubmitResetPasswordForm}>
              <DialogContent
                sx={{
                  backgroundColor: theme.palette.background.alt,
                  color: theme.palette.secondary[100],
                }}
              >
                <TextField
                  autoFocus
                  margin="dense"
                  id="newPassword"
                  label={t("customers.dialog.Please Enter New Password")}
                  type="text"
                  fullWidth
                  variant="standard"
                  required
                  value={resetPassword}
                  sx={{
                    width: "100%",
                    backgroundColor: theme.palette.background.alt,
                    color: `${theme.palette.secondary[200]} !important`,
                  }}
                  inputProps={{ maxLength: 12 }}
                  onChange={(e) => {
                    handlePasswordFieldChange(e);
                  }}
                />
                {errorMessage && (
                  <Alert variant="filled" severity="error">
                    {errorMessage}
                  </Alert>
                )}
              </DialogContent>
              <DialogActions
                sx={{
                  backgroundColor: theme.palette.background.alt,
                  color: theme.palette.secondary[100],
                }}
              >
                <Button
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    color: `${theme.palette.secondary[100]} !important`,
                  }}
                  onClick={handleGeneratePassword}
                >
                  {t("administrators.dialog.Generate Password")}
                </Button>
                <Button
                  type="reset"
                  onClick={handleCloseResetPasswordForm}
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    color: `${theme.palette.secondary[100]} !important`,
                  }}
                >
                  {t("administrators.dialog.CANCEL")}
                </Button>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: theme.palette.background.alt,
                    color: `${theme.palette.secondary[100]} !important`,
                  }}
                >
                  {t("administrators.dialog.SAVE")}
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </Box>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={1000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            //   severity={snackbarVariant}
            sx={{
              width: "100%",
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <ThemeProvider theme={themeWithLocale}>
            <DataGrid
              loading={isLoading || !data}
              rows={rows || []}
              columns={columns}
              getRowId={(row) => row.id}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              slots={{
                toolbar: EditToolbar,
              }}
              slotProps={{
                toolbar: { setRows, setRowModesModel, rows },
              }}
            />
          </ThemeProvider>
        </Box>
      </LocalizationProvider>
    </Box>
  );
};

export default Administrators;
