import React, { useState, useEffect } from "react";
import { Box, useMediaQuery, Typography, useTheme, Alert } from "@mui/material";
import {
  Outlet,
  useResolvedPath,
  useNavigate,
  redirect,
} from "react-router-dom";
import NavBar from "components/Navbar";
import Sidebar from "components/Sidebar";
import { useDispatch, useSelector } from "react-redux";
// import { useGetUserDetailsQuery } from "../../app/services/auth/authService";
import { setCredentials, logout } from "features/auth/authSlice";
import { store } from "../../app/store";
import { isExpired } from "../../utils/isExpired";
import { api } from "../../state/api";
import { authApi } from "../../app/services/auth/authService";

const Layout = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // get user data from localstorage
  const data = JSON.parse(localStorage.getItem("userData"));

  // everytime we click the link / refresh the page
  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (isExpired(token)) {
      dispatch(logout());
      dispatch(api.util.resetApiState()); // reset all api status
      dispatch(authApi.util.resetApiState()); // reset all api status
      navigate("/login");
    }
  });

  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
      <Sidebar
        user={data || {}}
        isNonMobile={isNonMobile}
        drawerWidth="250px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <Box flexGrow={1}>
        <NavBar
          user={data || {}}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        {data && <Outlet />}
      </Box>
    </Box>
  );
};

export default Layout;
