import React, { useEffect, useState, useMemo } from "react";
import { ResponsivePie } from "@nivo/pie";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const currencyFormatter = new Intl.NumberFormat("ja-JP", {
  style: "currency",
  currency: "JPY",
});

const numberFormatter = new Intl.NumberFormat("ja-JP");

const PieChart = ({ data = {}, isDashboard = false }) => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState();

  const theme = useTheme();
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  );

  const getCurrentLocale = () => {
    return t("currentLocale");
  };

  const currentLocale = getCurrentLocale();

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");

  if (!data) return "Loading...";

  const colors = [
    theme.palette.secondary[500],
    theme.palette.secondary[400],
    theme.palette.secondary[300],
    theme.palette.secondary[200],
    theme.palette.secondary[100],
  ];

  let totalAmount = 0;

  if (data) {
    totalAmount = Object.values(data).reduce((acc, curr) => acc + curr, 0);
  }

  let formattedData = Object.entries(data).map(([category, amount], i) => ({
    // id: category,
    id: t(`dashboard.piechart.${category}`),
    label: t(`dashboard.piechart.${category}`),
    value: amount,
    color: colors[i],
  }));

  return (
    <Box
      height={isDashboard ? "425px" : "100%"}
      width={undefined}
      minHeight={isDashboard ? "325px" : undefined}
      minWidth={isDashboard ? "325px" : undefined}
      position="relative"
    >
      <ResponsivePie
        data={formattedData}
        valueFormat={(value) => currencyFormatter.format(value)}
        theme={{
          axis: {
            domain: {
              line: {
                stroke: theme.palette.secondary[200],
              },
            },
            legend: {
              text: {
                fill: theme.palette.secondary[200],
              },
            },
            ticks: {
              line: {
                stroke: theme.palette.secondary[200],
                strokeWidth: 1,
              },
              text: {
                fill: theme.palette.secondary[200],
              },
            },
          },
          legends: {
            text: {
              fill: theme.palette.secondary[200],
            },
          },
          tooltip: {
            container: {
              color: theme.palette.primary[400],
            },
          },
        }}
        colors={{ datum: "data.color" }}
        margin={
          isDashboard
            ? { top: 40, right: 80, bottom: 100, left: 50 }
            : { top: 40, right: 80, bottom: 80, left: 80 }
        }
        sortByValue={true}
        innerRadius={0.45}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        enableArcLinkLabels={!isDashboard}
        arcLinkLabelsTextColor={theme.palette.secondary[200]}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        legends={[
          {
            anchor: isNonMediumScreens ? "right" : "bottom",
            direction: "column",
            justify: false,
            translateX: isDashboard ? 20 : 0,
            translateY: isDashboard ? 50 : 56,
            itemsSpacing: 0,
            itemWidth: 85,
            itemHeight: 18,
            itemTextColor: theme.palette.primary[100],
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 10,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: theme.palette.primary[400],
                },
              },
            ],
          },
        ]}
      />
      <Box
        position="absolute"
        top="50%"
        left="50%"
        color={theme.palette.secondary[400]}
        textAlign="center"
        pointerEvents="none"
        sx={{
          transform: isDashboard
            ? "translate(-75%, -170%)"
            : "translate(-50%, -100%)",
        }}
      >
        <Typography variant="h6">
          {!isDashboard && "f:"} {currencyFormatter.format(totalAmount)}
        </Typography>
      </Box>
    </Box>
  );
};

export default PieChart;
