import React, { useEffect, useMemo, useState } from "react";

import Header from "components/Header";

import { useGetSettingsQuery, useUpdateSettingMutation } from "state/api";

import {
  Box,
  Button,
  CircularProgress,
  useTheme,
  TextField,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormLabel,
  useMediaQuery,
} from "@mui/material";

import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  useGridApiContext,
  GRID_DATE_COL_DEF,
} from "@mui/x-data-grid";

import {
  Add as AddIcon,
  Save as SaveIcon,
  Close as CancelIcon,
  Edit as EditIcon,
  DeleteOutlined as DeleteIcon,
  YoutubeSearchedFor as ViewSettingIcon,
} from "@mui/icons-material";

import { Alert as MuiAlert, Tooltip } from "@mui/material";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Settings = () => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState();
  const theme = useTheme();
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  );

  const getCurrentLocale = () => {
    return t("currentLocale");
  };

  const currentLocale = getCurrentLocale();

  // set locale for datagrid
  useEffect(() => {
    if (currentLocale === "zh-Hant") {
      setLocale("zhHK");
    } else {
      setLocale("enUS");
    }
  }, [currentLocale]);

  const { data: settings, isLoading: isLoadingSettings } =
    useGetSettingsQuery(); // get all workflow

  useEffect(() => {
    if (typeof settings !== "undefined" && settings.length > 0) {
      console.log("get setting again");
      // always use the 1st entry
      setOrgSettings(settings[0]);
      setFormName(settings[0].name);
      setFormNameBig5(settings[0].nameBig5);
      setFormAddress(settings[0].address);
      setFormAddressBig5(settings[0].addressBig5);
      setFormPhone(settings[0].phone);
      setFormEmail(settings[0].email);
    } else {
      // use default
      setOrgSettings({
        name: "",
        nameBig5: "",
        address: "",
        addressBig5: "",
        phone: "",
        email: "",
      });
      setFormName("");
      setFormNameBig5("");
      setFormAddress("");
      setFormAddressBig5("");
      setFormPhone("");
      setFormEmail("");
    }
  }, [settings]);

  const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [patchSetting, { isLoading: isPatching }] = useUpdateSettingMutation();

  //reset form
  const handleReset = () => {
    reset(orgSettings);
    setFormName(orgSettings.name);
    setFormNameBig5(orgSettings.nameBig5);
    setFormAddress(orgSettings.address);
    setFormAddressBig5(orgSettings.addressBig5);
    setFormPhone(orgSettings.phone);
    setFormEmail(orgSettings.email);
  };

  //form data
  const [orgSettings, setOrgSettings] = useState({});
  const [formName, setFormName] = useState("");
  const [formNameBig5, setFormNameBig5] = useState("");
  const [formAddress, setFormAddress] = useState("");
  const [formAddressBig5, setFormAddressBig5] = useState("");
  const [formPhone, setFormPhone] = useState("");
  const [formEmail, setFormEmail] = useState("");

  const handleNameChange = (event) => {
    setFormName(event.target.value);
    setValue("name", event.target.value);
  };

  const handleNameBig5Change = (event) => {
    setFormNameBig5(event.target.value);
    setValue("nameBig5", event.target.value);
  };
  const handleAddressChange = (event) => {
    setFormAddress(event.target.value);
    setValue("address", event.target.value);
  };
  const handleAddressBig5Change = (event) => {
    setFormAddressBig5(event.target.value);
    setValue("addressBig5", event.target.value);
  };
  const handlePhoneChange = (event) => {
    setFormPhone(event.target.value);
    setValue("phone", event.target.value);
  };
  const handleEmailChange = (event) => {
    setFormEmail(event.target.value);
    setValue("email", event.target.value);
  };

  // *** snackbar *** //
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const onSubmit = async (data) => {
    // call api to save the data
    try {
      let response = {};
      response = await patchSetting({
        data: {
          name: formName,
          nameBig5: formNameBig5,
          address: formAddress,
          addressBig5: formAddressBig5,
          phone: formPhone,
          email: formEmail,
        },
      }).unwrap();

      // get message
      if (response.message === "Done!") {
        setSnackbarSeverity("success");
        setSnackbarMessage(t("system settings.System Set"));
        setOpenSnackbar(true); //show snackbar
      }
    } catch (error) {
      const error_message = error.data.message;
      setSnackbarSeverity("error");
      setSnackbarMessage("Error! " + error_message);
      setOpenSnackbar(true); //show snackbar
    }

    reset(); //reset form
  };

  const schema = yup.object().shape({
    name: yup.string(),
    nameBig5: yup.string(),
    address: yup.string(),
    addressBig5: yup.string(),
    phone: yup.string(),
    email: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const inputForm = () => {
    let content;
    content = (
      <Box m="1.5rem 2.5rem">
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbarSeverity}
            sx={{
              width: "100%",
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Box>
          <Box gridColumn="span 4"></Box>
          <Box
            gridColumn="span 4"
            backgroundColor={theme.palette.background.alt}
            p="1rem"
            borderRadius="0.55rem"
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box sx={{ width: "100%" }}>
                <TextField
                  id="name"
                  label={t("system settings.name")}
                  type="text"
                  variant="standard"
                  sx={{ width: "100%" }}
                  {...register("name")}
                  value={formName}
                  onChange={handleNameChange}
                />

                <TextField
                  id="nameBig5"
                  label={t("system settings.nameBig5")}
                  type="text"
                  variant="standard"
                  sx={{ width: "100%" }}
                  {...register("nameBig5")}
                  value={formNameBig5}
                  onChange={handleNameBig5Change}
                />

                <TextField
                  id="address"
                  label={t("system settings.address")}
                  type="text"
                  variant="standard"
                  sx={{ width: "100%" }}
                  {...register("address")}
                  value={formAddress}
                  onChange={handleAddressChange}
                />
                <TextField
                  id="addressBig5"
                  label={t("system settings.addressBig5")}
                  type="text"
                  variant="standard"
                  sx={{ width: "100%" }}
                  {...register("addressBig5")}
                  value={formAddressBig5}
                  onChange={handleAddressBig5Change}
                />
                <TextField
                  id="phone"
                  label={t("system settings.phone")}
                  type="text"
                  variant="standard"
                  sx={{ width: "100%" }}
                  {...register("phone")}
                  value={formPhone}
                  onChange={handlePhoneChange}
                />
                <TextField
                  id="email"
                  label={t("system settings.email")}
                  type="text"
                  variant="standard"
                  sx={{ width: "100%" }}
                  {...register("email")}
                  value={formEmail}
                  onChange={handleEmailChange}
                />
                <Box p="1rem 0rem 0rem 0rem" align="right">
                  <Button
                    type="reset"
                    sx={{
                      backgroundColor: theme.palette.primary[200],
                      color: theme.palette.grey[0],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                    onClick={handleReset}
                  >
                    {t("system settings.Reset")}
                  </Button>{" "}
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: theme.palette.primary[200],
                      color: theme.palette.grey[0],
                      fontSize: "14px",
                      fontWeight: "bold",
                      padding: "10px 20px",
                    }}
                  >
                    {t("system settings.Submit")}
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
          <Box gridColumn="span 4"></Box>
        </Box>
      </Box>
    );
    return content;
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title={t("system settings.SYSTEM SETTINGS")} subtitle="" />
      {inputForm()}
    </Box>
  );
};

export default Settings;
