// viewWorkflow - client (no need to login) to view the workflow + meeting info
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Divider,
  Typography,
  useTheme,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Tooltip,
  List,
  Backdrop,
  Stepper,
  Step,
  StepLabel,
  Link,
} from "@mui/material";

import logoImage from "assets/logo.png";

import { useLazyGetWorkflowQuery } from "state/api";
import { use } from "i18next";

const ViewWorkflow = () => {
  const theme = useTheme();
  const { path } = useParams();
  // get the data
  const [trigger, result] = useLazyGetWorkflowQuery();

  const steps = [
    "開始工作流程",
    "客戶提交文件",
    "核實文件",
    "會議及簽約",
    "完成",
  ];

  //for snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [meetingDateStr, setMeetingDateStr] = useState(null);
  const {
    data: workflow,
    error: workflowError,
    isError: isWorkflowError,
    isLoading: isLoadingWorkflow,
    isFetching: isFetchWorkflow,
  } = result;

  useEffect(() => {
    //fetch
    trigger({ path: path });
  }, [path]);

  let date = new Date();
  useEffect(() => {
    if (workflow) {
      setActiveStep(workflow.currentStep);
      date = new Date(workflow.zoomDate);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");

      setMeetingDateStr(year + "-" + month + "-" + day);
    }
  }, [workflow]);

  return (
    <Box
      p="1rem 1rem"
      flex="1 1 100%"
      backgroundColor={theme.palette.background.alt}
      borderRadius="0.55rem"
      style={{ width: "80vw" }}
    >
      <Box
        component="img"
        alt="logo"
        src={logoImage}
        sx={{ objectFit: "cover" }}
      />
      <br />
      <Typography component="h1" variant="h5">
        <center>
          <strong>富 盈 策 略 控 股 有 限 公 司</strong>
        </center>
      </Typography>
      <br />
      <Divider>
        <small>工 作 流 程</small>
      </Divider>
      <Box m="1rem 1rem" sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <Divider />
      <Box m="1rem 1rem">
        <b>標 題：</b>
        {workflow?.title} <br />
        <b>簡 要 描 述：</b>
        {workflow?.description} <br />
        {workflow &&
          workflow.currentStep == 3 &&
          workflow.zoomLink !== null && (
            <>
              <Typography>
                <strong>會 議 日 期 (YYYY-MM-DD)：</strong>
                {meetingDateStr}
              </Typography>
              <Typography>
                <strong>會 議 時 間 (24HH:MM)：</strong>
                {workflow?.zoomTime}
              </Typography>
              <Typography>
                <strong>會 議 所 需 時 間 ：</strong>約{" "}
                {workflow?.zoomPeriodInHour} 小時
              </Typography>
              <Typography>
                <strong>ZOOM 連 結：</strong>{" "}
                <Link href={workflow.zoomLink} target="_blank" color="inherit">
                  {workflow.zoomLink}
                </Link>
              </Typography>
            </>
          )}
        {workflow && workflow.currentStep < 3 && (
          <>
            <Typography>
              <strong>會 議 日 期 (YYYY-MM-DD)：</strong>
              待定
            </Typography>
            <Typography>
              <strong>會 議 時 間 (24HH:MM)：</strong>
              待定
            </Typography>
            <Typography>
              <strong>會 議 所 需 時 間 ：</strong>待定
            </Typography>
            <Typography>
              <strong>ZOOM 連 結：</strong> 待定
            </Typography>
          </>
        )}
        {workflow && workflow.currentStep > 3 && (
          <>
            <Typography>
              <strong>會 議 日 期 (YYYY-MM-DD)：</strong>
              已完成
            </Typography>
            <Typography>
              <strong>會 議 時 間 (24HH:MM)：</strong>
              已完成
            </Typography>
            <Typography>
              <strong>會 議 所 需 時 間 ：</strong>已完成
            </Typography>
            <Typography>
              <strong>ZOOM 連 結：</strong> 已完成
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ViewWorkflow;
