import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { userLogin } from "../../features/auth/authActions";
import FlexBetween from "components/FlexBetween";
import logoImage from "assets/logo.png";

const Login = () => {
  const theme = useTheme();
  const { loading, userInfo, error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  // const error = null;
  // const loading = false;
  // redirect authenticated user to dashboard screen
  // get userData

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      const tmp = JSON.parse(userData);
      const role = tmp["role"];
      // check if customer or admin
      if (role === "customer") {
        navigate("/dashboard");
        localStorage.setItem("role", "customer");
      } else if (role === "admin") {
        navigate("/customers");
        localStorage.setItem("role", "customer");
      } else navigate("/404");
    }
  }, [navigate, userInfo]);

  const submitForm = (data) => {
    dispatch(userLogin(data));
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <Box
        gridColumn="span 2"
        gridRow="span 1"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        p="1.25rem 1rem"
        flex="1 1 100%"
        backgroundColor={theme.palette.background.alt}
        borderRadius="0.55rem"
      >
        <Box
          component="img"
          alt="logo"
          src={logoImage}
          sx={{ objectFit: "cover" }}
        />
        <br />
        <Typography component="h1" variant="h5">
          <center>
            <strong>富 盈 策 略 控 股 有 限 公 司</strong>
          </center>
        </Typography>
        <br />
        <Divider>
          <small>客 戶 資 源 管 理 系 統</small>
        </Divider>
        <br />
        {error && (
          <Box p="1rem 1rem">
            <Alert severity="warning" variant="outlined">
              {error}
            </Alert>
          </Box>
        )}
        <TextField
          id="email"
          label="登 入 電 郵"
          type="text"
          variant="standard"
          {...register("email")}
          required
        />

        <TextField
          id="password"
          label="登 入 密 碼"
          type="password"
          variant="standard"
          {...register("password")}
          required
        />
        <Box p="1rem 0rem 0rem 0rem" align="right">
          <Button
            sx={{
              backgroundColor: theme.palette.primary[200],
              color: theme.palette.grey[0],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            disabled={loading}
          >
            取 消
          </Button>{" "}
          <Button
            type="submit"
            sx={{
              backgroundColor: theme.palette.primary[200],
              color: theme.palette.grey[0],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
            disabled={loading}
          >
            登 入
            {loading ? (
              <CircularProgress size={20} style={{ marginLeft: 10 }} />
            ) : (
              <></>
            )}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default Login;
