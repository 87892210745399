import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Outlet, useResolvedPath } from "react-router-dom";
import { useSelector } from "react-redux";

const LoginLayout = () => {
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  return (
    // <Box display={isNonMobile ? "flex" : "block"} width="50%" height="50%">
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Box display={1} minWidth="50vh">
        <Outlet />
      </Box>
    </Box>
  );
};

export default LoginLayout;
