import axios from "axios";

const backendURL = process.env.REACT_APP_BASE_URL;

// internal files
const getAssetDocumentFileApi = async (fileId, assetDocumentId) => {
  const token = localStorage.getItem("userToken");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  // Make an API request to the backend
  let response = {};
  try {
    response = await axios.get(backendURL + `/pi/file/${fileId}`, {
      responseType: "blob",
      headers: headers,
      params: {
        assetDocumentId: assetDocumentId,
      },
    });
  } catch (error) {
    console.log("error:", error);
  }

  return response; // don't do anything
};

const getFileApi = async (fileId, transactionId) => {
  const token = localStorage.getItem("userToken");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  // Make an API request to the backend
  let response = {};
  try {
    response = await axios.get(backendURL + `/p/file/${fileId}`, {
      responseType: "blob",
      headers: headers,
      params: {
        transactionId: transactionId,
      },
    });
  } catch (error) {
    console.log("error:", error);
  }

  return response; // don't do anything
};

const getFileFromUserApi = async (fileId, transactionId) => {
  const token = localStorage.getItem("userToken");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  // Make an API request to the backend
  let response = {};
  try {
    response = await axios.get(backendURL + `/p/u/file/${fileId}`, {
      responseType: "blob",
      headers: headers,
      params: {
        transactionId: transactionId,
      },
    });
  } catch (error) {
    console.log("error:", error);
  }

  return response; // don't do anything
};

const getImageFilesFromUserApi = async (transactionId) => {
  const token = localStorage.getItem("userToken");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  // Make an API request to the backend
  let response = {};
  try {
    response = await axios.get(backendURL + `/p/u/images/${transactionId}`, {
      responseType: "json",
      headers: headers,
    });
  } catch (error) {
    console.log("error:", error);
  }

  return response; // don't do anything
};

const getQuotationFileApi = async (fileId, quotationId) => {
  const token = localStorage.getItem("userToken");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  // Make an API request to the backend
  let response = {};
  try {
    //response = await axios.get(backendURL + `/qi/file/${fileId}`, {
    response = await axios.get(
      backendURL + `/client/quotation/file/${fileId}`,
      {
        //qi === quotation, internal
        responseType: "blob",
        headers: headers,
        params: {
          quotationId: quotationId,
        },
      }
    );
  } catch (error) {
    console.log("error:", error);
  }

  return response; // don't do anything
};

const getClientQuotationFileApi = async (fileId, quotationId) => {
  // Make an API request to the backend
  let response = {};
  let headers = {};
  try {
    response = await axios.get(
      backendURL + `/client/quotation/file/${fileId}`,
      {
        responseType: "blob",
        headers: headers,
        params: {
          quotationId: quotationId,
        },
      }
    );
  } catch (error) {
    console.log("error:", error);
  }

  return response; // don't do anything
};

// internal files
const getAccountDocumentFileApi = async (fileId, accountDocumentId) => {
  const token = localStorage.getItem("userToken");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  // Make an API request to the backend
  let response = {};
  try {
    response = await axios.get(backendURL + `/pa/file/${fileId}`, {
      responseType: "blob",
      headers: headers,
      params: {
        accountDocumentId: accountDocumentId,
      },
    });
  } catch (error) {
    console.log("error:", error);
  }

  return response; // don't do anything
};

// internal files
const getClientDocumentFileApi = async (fileId, clientDocumentId) => {
  const token = localStorage.getItem("userToken");
  let headers = {};
  if (token) {
    headers = {
      Authorization: `Bearer ${token}`,
    };
  }
  // Make an API request to the backend
  let response = {};
  try {
    response = await axios.get(backendURL + `/pc/file/${fileId}`, {
      responseType: "blob",
      headers: headers,
      params: {
        clientDocumentId: clientDocumentId,
      },
    });
  } catch (error) {
    console.log("error:", error);
  }

  return response; // don't do anything
};

export {
  getFileApi,
  getFileFromUserApi,
  getImageFilesFromUserApi,
  getAssetDocumentFileApi,
  getQuotationFileApi,
  getClientQuotationFileApi,
  getAccountDocumentFileApi,
  getClientDocumentFileApi,
};
