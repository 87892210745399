import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import themeModeReducer from "../features/themeMode/themeModeSlice";
import authReducer from "../features/auth/authSlice";
import { api } from "../state/api"; // admin
import { userApi } from "../state/userApi";
import { authApi } from "../app/services/auth/authService";
export const store = configureStore({
  reducer: {
    themeMode: themeModeReducer,
    auth: authReducer,
    [api.reducerPath]: api.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(api.middleware)
      .concat(authApi.middleware)
      .concat(userApi.middleware),
});

// configure listeners using the provided defaults
setupListeners(store.dispatch);
