import React, { useEffect, useMemo, useState } from "react";
import FlexBetween from "./FlexBetween";
import { useUploadAccountDocumentFilesFromAdminMutation } from "state/api";
import MuiAlert from "@mui/material/Alert";

import {
  Box,
  Button,
  CircularProgress,
  Snackbar,
  useTheme,
} from "@mui/material";
import { useDropzone } from "react-dropzone";

import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";
import { createTheme } from "@mui/material/styles";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function DropboxAccountDocument(props) {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState();
  const theme = useTheme();
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  );

  const getCurrentLocale = () => {
    return t("currentLocale");
  };

  const currentLocale = getCurrentLocale();

  // set locale for datagrid
  useEffect(() => {
    if (currentLocale === "zh-Hant") {
      setLocale("zhHK");
    } else {
      setLocale("enUS");
    }
  }, [currentLocale]);
  const { accountDocumentId } = props;
  const [uploadFiles, setUploadFiles] = useState([]);

  //for snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const [uploadAccountDocumentFiles, { isLoading: isUploading }] =
    useUploadAccountDocumentFilesFromAdminMutation();

  const onDropAccepted = (files) => {
    setUploadFiles(files);
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    inputRef,
  } = useDropzone({
    onDropAccepted,
    accept: {
      "image/*": [],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "video/*": [],
      "text/plain": [".txt"],
    },
    maxSize: 52428800, // 50MB
  });

  const files = acceptedFiles.map((file) => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
    </li>
  ));

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const handleUploadClick = () => async () => {
    //prepare formData
    try {
      let formData = new FormData();
      formData.append("internal", true); // must be put before files
      acceptedFiles.forEach((file) => {
        formData.append("files", file);
      });

      let response = await uploadAccountDocumentFiles({
        id: accountDocumentId,
        data: formData,
      }).unwrap();
      // set the snackbar message
      setSnackbarMessage(t("Dropbox.Files Uploaded"));
      // show snackbar
      setOpenSnackbar(true);

      setUploadFiles([]); // for clean up the file list
    } catch (error) {
      setSnackbarMessage("Error: " + error);
      // show snackbar
      setOpenSnackbar(true);
    }
  };

  // *** snackbar *** //
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <>
      {/** Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={1000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          sx={{
            width: "100%",
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.background.alt,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {t("Dropbox.drag_n_drop")}
        </div>
        <Box>
          <FlexBetween>
            <aside>
              {uploadFiles.length > 0 && <h4>{t("Dropbox.File Info")}</h4>}
              {uploadFiles.length > 0 && <ul>{files}</ul>}
            </aside>
          </FlexBetween>
          <FlexBetween m="1rem 0rem 0rem 0rem">
            {uploadFiles.length > 0 && !isUploading && (
              <Button
                sx={{
                  backgroundColor: theme.palette.primary[200],
                  color: theme.palette.grey[0],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                }}
                //   onClick={props.handleUpload}
                onClick={handleUploadClick()}
              >
                {t("Dropbox.UPLOAD")}
              </Button>
            )}
            {uploadFiles.length > 0 && isUploading && (
              <>
                <Button
                  sx={{
                    backgroundColor: theme.palette.primary[200],
                    color: theme.palette.grey[0],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "10px 20px",
                  }}
                  disabled
                >
                  {t("Dropbox.uploading")}{" "}
                  <CircularProgress
                    sx={{ padding: "5px 5px" }}
                    variant="indeterminate"
                  />
                </Button>
              </>
            )}
          </FlexBetween>
        </Box>
      </div>
    </>
  );
}

export default DropboxAccountDocument;
