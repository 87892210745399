import React, { Component } from "react";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.options.autoDispose = true;

export default class JapanMap extends Component {
  componentDidMount() {
    am4core.useTheme(am4themes_animated);

    // Create map instance
    var chart = am4core.create("chartdiv", am4maps.MapChart);

    // Set map definition
    chart.geodataSource.url = "./japanLow.json";

    // Set projection
    chart.projection = new am4maps.projections.Mercator();

    // Add zoom control
    chart.zoomControl = new am4maps.ZoomControl();

    // Set initial zoom
    chart.homeZoomLevel = 1;

    // Create map polygon series
    var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.mapPolygons.template.strokeWidth = 0.5;

    //Set min/max fill color for each area
    polygonSeries.heatRules.push({
      property: "fill",
      target: polygonSeries.mapPolygons.template,
      min: chart.colors.getIndex(1).brighten(1),
      max: chart.colors.getIndex(1).brighten(0),
      logarithmic: false,
    });
    // Make map load polygon data (state shapes and names) from GeoJSON
    polygonSeries.useGeodata = true;

    // Set heatmap values for each state
    // console.log("this.props.data", this.props.data);
    polygonSeries.data = this.props.data;

    // Set up heat legend
    let heatLegend = chart.createChild(am4maps.HeatLegend);
    heatLegend.series = polygonSeries;
    heatLegend.align = "right";
    heatLegend.valign = "bottom";
    heatLegend.height = am4core.percent(80);
    heatLegend.orientation = "vertical";
    heatLegend.valign = "middle";
    heatLegend.marginRight = am4core.percent(4);
    heatLegend.valueAxis.renderer.opposite = true;
    heatLegend.valueAxis.renderer.dx = -25;
    heatLegend.valueAxis.strictMinMax = false;
    heatLegend.valueAxis.fontSize = 9;
    heatLegend.valueAxis.logarithmic = true;

    // Configure series tooltip
    var polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}: {value}";
    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeWidth = 0.5;

    // Create hover state and set alternative fill color
    var hs = polygonTemplate.states.create("hover");
    hs.properties.fill = am4core.color("#3c5bdc");

    // heat legend behavior
    polygonSeries.mapPolygons.template.events.on("over", function (event) {
      handleHover(event.target);
    });

    polygonSeries.mapPolygons.template.events.on("hit", function (event) {
      handleHover(event.target);
    });

    function handleHover(column) {
      if (!isNaN(column.dataItem.value)) {
        heatLegend.valueAxis.showTooltipAt(column.dataItem.value);
      } else {
        heatLegend.valueAxis.hideTooltip();
      }
    }

    polygonSeries.mapPolygons.template.events.on("out", function (event) {
      heatLegend.valueAxis.hideTooltip();
    });
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
  render() {
    return (
      <div>
        <div id="chartdiv" style={{ width: "100%", height: "450px" }}></div>
      </div>
    );
  }
}
