import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import DropboxAssetDocument from "../../components/DropboxAssetDocument";
import Header from "components/Header";

import { formatBytes } from "../../utils/formateBytes";
import { getAssetDocumentFileApi } from "../../features/file/fileActions";

import {
  useGetAllAssetsFromAdminQuery,
  useLazyGetAssetDocumentsFromAdminQuery,
  useUpdateAssetDocumentFromAdminMutation,
  useAddAssetDocumentFromAdminMutation,
  useDeleteAssetDocumentFromAdminMutation,
  useLazyGetAssetDocumentFilesFromAdminQuery,
  useDeleteAssetDocumentFilesFromAdminMutation,
} from "state/api";

import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  useTheme,
  TextField,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Modal,
  Typography,
} from "@mui/material";

import { createFilterOptions } from "@mui/material/Autocomplete";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  useGridApiContext,
} from "@mui/x-data-grid";

import {
  Add as AddIcon,
  Save as SaveIcon,
  Close as CancelIcon,
  Edit as EditIcon,
  AttachFile as AttachFileIcon,
  DeleteOutlined as DeleteIcon,
  PreviewOutlined as PreviewOutlinedIcon,
} from "@mui/icons-material";

import { Alert as MuiAlert, Tooltip } from "@mui/material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { useTranslation } from "react-i18next";
import * as locales from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function EditToolbar(props) {
  const { t, i18n } = useTranslation();
  const { setRows, setRowModesModel, rows } = props;

  const handleAddRecordClick = () => {
    //get the row id

    const newId =
      rows.length === 0
        ? 1
        : Math.max(...rows.map((r) => (r.id ? r.id : 0) * 1)) + 1;
    const id = newId;
    setRows((oldRows) => [
      ...oldRows,
      {
        id: newId,
        documentType: "",
        remark: "",
        numOfFiles: 0,
        isNew: true,
      },
    ]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "documentType" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleAddRecordClick}
      >
        {t("assetDocuments.Add Asset Document")}
      </Button>
    </GridToolbarContainer>
  );
}

const AssetDocuments = () => {
  const { t, i18n } = useTranslation();
  const [locale, setLocale] = useState();
  const theme = useTheme();
  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[locale]),
    [locale, theme]
  );

  const getCurrentLocale = () => {
    return t("currentLocale");
  };

  const currentLocale = getCurrentLocale();

  // set locale for datagrid
  useEffect(() => {
    if (currentLocale === "zh-Hant") {
      setLocale("zhHK");
    } else {
      setLocale("enUS");
    }
  }, [currentLocale]);
  const navigate = useNavigate();
  const location = useLocation();

  const { data, isLoading } = useGetAllAssetsFromAdminQuery(); // get asset data
  const [lstAsset, setLstAsset] = useState([]);

  const [optionDefaultValue, setOptionDefaultValue] = useState(null);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [selectedAssetName, setSelectedAssetName] = useState("");
  const [selectedAssetCustomerId, setSelectedAssetCustomerId] = useState("");
  const [selectedAssetDocumentId, setSelectedAssetDocumentId] = useState(null);
  const [skip, setSkip] = useState(true);

  const [rows, setRows] = useState();
  const [rowModesModel, setRowModesModel] = useState({});

  const [previewUrl, setPreviewUrl] = useState("");
  const [showPreview, setShowPreview] = useState(false);

  const handleShowPreview = () => {
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const [openConfirmDeleteFileDialog, setOpenConfirmDeleteFileDialog] =
    useState(false);
  const [deleteFileId, setDeleteFileId] = useState("");

  //for snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  //for file modal
  const [openFileModal, setOpenFileModal] = React.useState(false);
  const handleOpenFileModal = () => setOpenFileModal(true);
  const handleCloseFileModal = () => setOpenFileModal(false);

  const [updateAssetDocument, { isLoading: isUpdating }] =
    useUpdateAssetDocumentFromAdminMutation();

  const [addAssetDocument, { isLoading: isAdding }] =
    useAddAssetDocumentFromAdminMutation();

  const [deleteAssetDocument, { isLoading: isDeleting }] =
    useDeleteAssetDocumentFromAdminMutation();

  const [deleteAssetDocumentFile, { isLoading: isDeletingFile }] =
    useDeleteAssetDocumentFilesFromAdminMutation();

  const [trigger, result] = useLazyGetAssetDocumentsFromAdminQuery();
  const [triggerFiles, { isLoading: isLoadingFiles, data: resultFiles }] =
    useLazyGetAssetDocumentFilesFromAdminQuery();

  const {
    data: assetDocuments,
    error: assetDocumentsError,
    isError: isAssetDocumentsError,
    isLoading: isLoadingAssetDocuments,
    isFetching: isFetchAssetDocuments,
  } = result;

  // file modal
  const fileModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: theme.palette.primary.main,
    border: "0px",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    // update the autocomplete data
    var tmp = [];
    for (var i in data) tmp.push(data[i]);
    setLstAsset(tmp);
  }, [isLoading]);

  useEffect(() => {
    // check if there is any location.state data
    try {
      const currentAssetId = location.state.asset_id;

      for (var i in lstAsset) {
        if (lstAsset[i]._id === currentAssetId) {
          setOptionDefaultValue(lstAsset[i]);
          setSelectedAssetName(lstAsset[i].name);
          setSelectedAssetCustomerId(lstAsset[i].ownedBy);
          break;
        }
      }
      setSelectedAssetId(currentAssetId);
      setSkip(false);
      //clean location state
    } catch (error) {
      //do nothing
    }
  }, [lstAsset]);

  useEffect(() => {
    if (!skip) {
      //fetch
      trigger({ id: selectedAssetId });
    }
  }, [selectedAssetId]);

  useEffect(() => {
    if (!skip) {
      //fetch
      triggerFiles({ id: selectedAssetDocumentId });
    }
  }, [selectedAssetDocumentId]);

  // update the data grid row, if loaded with new data
  useEffect(() => {
    if (!result.isLoading) {
      setRows(assetDocuments);
    }
  }, [result.isFetching]);

  const handleSelectedAsset = async (value) => {
    setOptionDefaultValue(value);
    if (value) {
      // get the related assets
      let response = {};
      try {
        setSelectedAssetId(value._id);
        setSelectedAssetName(value.name);
        setSelectedAssetCustomerId(value.ownedBy);
        setSkip(false);
      } catch (error) {
        setSnackbarMessage("Error: " + error);
        // show snackbar
        setOpenSnackbar(true);
      }
    } else {
      setSelectedAssetId(null);
      setSelectedAssetName("");
      setSelectedAssetCustomerId("");
      setSkip(true);
    }
  };

  // *** snackbar *** //
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handlePreviewFileClick = (id) => async () => {
    try {
      let resp = {};
      resp = await getAssetDocumentFileApi(id, selectedAssetDocumentId);
      // get the file type
      const contentType = resp.headers["content-type"];
      // Check the file type and set the preview URL accordingly
      if (contentType.startsWith("image/")) {
        const imageUrl = URL.createObjectURL(new Blob([resp.data]));
        setPreviewUrl(imageUrl);
        handleShowPreview();
      } else {
        // For documents or videos, open a new tab for previewing/downloading
        window.open(
          URL.createObjectURL(new Blob([resp.data], { type: contentType }))
        );
      }
    } catch (error) {
      setSnackbarMessage("Error:" + error);
      // show snackbar
      setOpenSnackbar(true);
    }
  };

  // *** confirm delete dialog *** //
  const handleCloseConfirmDeleteDialog = () => {
    setDeleteFileId("");
    setOpenConfirmDeleteDialog(false);
  };

  // *** confirm delete File dialog *** //
  const handleDeleteFileClick = (id) => () => {
    // save the row id
    setDeleteFileId(id);
    setOpenConfirmDeleteFileDialog(true);
  };

  // *** confirm delete file dialog *** //
  const handleCloseConfirmDeleteFileDialog = () => {
    setDeleteFileId("");
    setOpenConfirmDeleteFileDialog(false);
  };

  // *** confirm delete file dialog *** //
  const handleSubmitConfirmDeleteFileDialog = async () => {
    try {
      let response = {};
      //updating
      response = await deleteAssetDocumentFile({
        id: selectedAssetDocumentId,
        body: { fileId: deleteFileId },
      }).unwrap();
      // get message
      if (response.message === "Delete Done!") {
        // set the snackbar message
        setSnackbarMessage(t("assetDocuments.snackbar.Record Deleted"));
        // show snackbar
        setOpenSnackbar(true);
      }
    } catch (error) {
      setSnackbarMessage("Error:" + error);
      // show snackbar
      setOpenSnackbar(true);
    }
    // //update the display
    // setRows(rows.filter((row) => row.id !== deleteId));
    //reset
    setDeleteFileId("");
    setOpenConfirmDeleteFileDialog(false);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleDeleteClick = (id) => () => {
    // save the row id
    setDeleteId(id);
    setOpenConfirmDeleteDialog(true);
  };

  const handleManageFilesClick = (id) => () => {
    let selectedRow = rows.find((row) => row.id === id);
    // update the selected asset document id

    setSelectedAssetDocumentId(selectedRow["_id"]);
    //show attache file modal
    handleOpenFileModal();
  };

  const handleSubmitConfirmDeleteDialog = async () => {
    let selectedRow = rows.find((row) => row.id === deleteId);
    // // call api to delete the administrator
    try {
      let response = {};
      //updating
      response = await deleteAssetDocument({
        id: selectedRow["_id"], // actual id
      }).unwrap();
      // get message
      if (response.message === "Delete Done!") {
        // set the snackbar message
        setSnackbarMessage(t("assetDocuments.snackbar.Record Deleted"));
        // show snackbar
        setOpenSnackbar(true);
      }
    } catch (error) {
      // set the snackbar message
      setSnackbarMessage("Error: " + error);
      // show snackbar
      setOpenSnackbar(true);
    }
    //update the display
    setRows(rows.filter((row) => row.id !== deleteId));
    //reset
    setDeleteId("");
    setOpenConfirmDeleteDialog(false);
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };
  const handleProcessRowUpdateError = React.useCallback((error) => {
    // showSnackbar("error", error.message);
    //setSnackbarMessage("Error! - " + error.message);
  }, []);
  const processRowUpdate = async (newRow) => {
    try {
      let response = {};
      if (newRow["isNew"]) {
        newRow["customerId"] = selectedAssetCustomerId;

        //insert new record
        response = await addAssetDocument({
          id: selectedAssetId,
          data: newRow,
        }).unwrap();
        //get _id
        const new_id = response._id; //asset document _id
        newRow["_id"] = new_id;
      } else {
        //updating
        response = await updateAssetDocument({
          id: newRow["_id"],
          data: newRow,
        }).unwrap();
      }
      //get response message
      if (
        response.message === "Update Done!" ||
        response.message === "Add Done!" ||
        response.message === "Delete Done!"
      ) {
        // set the snackbar message
        if (response.message === "Update Done!")
          setSnackbarMessage(t("assetDocuments.snackbar.Record Updated"));
        else if (response.message === "Add Done!")
          setSnackbarMessage(t("assetDocuments.snackbar.Record Added"));
        else if (response.message === "Delete Done!")
          setSnackbarMessage(t("assetDocuments.snackbar.Record Deleted"));
        // show snackbar
        setOpenSnackbar(true);
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
      }
    } catch (error) {
      return null;
    }
  };

  const renderListHeader = () => {
    let renderListHeader = (
      <Box>
        <h4>{t("assetDocuments.modal.List of current files")}</h4>
      </Box>
    );
    if (!isLoadingFiles && resultFiles) {
      renderListHeader = (
        <Box>
          <h4>
            {t("assetDocuments.modal.List of current files")} (
            {t("assetDocuments.modal.Total")} {resultFiles.length}{" "}
            {t("assetDocuments.modal.files")})
          </h4>
        </Box>
      );
    }
    return renderListHeader;
  };
  const renderFileList = () => {
    let renderListItem = <></>;
    if (!isLoadingFiles && resultFiles) {
      renderListItem = resultFiles.map((e, idx) => (
        <ListItem key={e._id}>
          <ListItemText
            primary={`${idx + 1}. ${e.originalname}`}
            secondary={`(File size: ${formatBytes(e.size)} bytes)`}
          />
          <ListItemIcon>
            <IconButton
              edge="end"
              aria-label={t("assetDocuments.modal.preview")}
              onClick={handlePreviewFileClick(e._id)}
            >
              <Tooltip title={t("assetDocuments.modal.preview")}>
                <PreviewOutlinedIcon />
              </Tooltip>
            </IconButton>
          </ListItemIcon>
          <ListItemIcon>
            <IconButton
              edge="end"
              aria-label={t("assetDocuments.modal.delete")}
              onClick={handleDeleteFileClick(e._id)}
            >
              <Tooltip title={t("assetDocuments.modal.delete")}>
                <DeleteIcon />
              </Tooltip>
            </IconButton>
          </ListItemIcon>
        </ListItem>
      ));
    }

    const content = (
      <List style={{ minWidth: 100, maxHeight: 200, overflow: "scroll" }}>
        {renderListItem}
      </List>
    );
    return content;
  };

  const filterOptions = createFilterOptions({
    limit: 500, // only show 500 entries, the rest need to be search
  });

  const columns = [
    { field: "id", headerName: "No", flex: 0.01, editable: false },
    {
      field: "documentType",
      headerName: t("assetDocuments.header.Document Type"),
      flex: 0.05,
      editable: true,
      type: "singleSelect",
      valueOptions: [
        {
          value: "Bank Statement",
          label: t("assetDocuments.select.Bank Statement"),
        },
        {
          value: "Commission Invoice",
          label: t("assetDocuments.select.Commission Invoice"),
        },
        {
          value: "TT Slip",
          label: t("assetDocuments.select.TT Slip"),
        },
        {
          value: "Appointment Document",
          label: t("assetDocuments.select.Appointment Document"),
        },
        {
          value: "Sale Document",
          label: t("assetDocuments.select.Sale Document"),
        },
        {
          value: "Scan Document",
          label: t("assetDocuments.select.Scan Document"),
        },
        {
          value: "Lease Management Document",
          label: t("assetDocuments.select.Lease Management Document"),
        },
      ],
    },
    {
      field: "remark",
      headerName: t("assetDocuments.header.Remark"),
      flex: 0.05,
      editable: true,
      type: "text",
    },
    {
      field: "numOfFiles",
      headerName: t("assetDocuments.header.Num of Files"),
      flex: 0.05,
      editable: false,
    },
    {
      field: "actions",
      type: "actions",
      headerName: t("assetDocuments.header.Actions"),
      width: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode && !isUpdating && !isAdding) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        if (isUpdating || isAdding) {
          return [
            <>
              <CircularProgress size="1.5rem" />
            </>,
          ];
        }

        return [
          <GridActionsCellItem
            icon={
              <Tooltip title={t("assetDocuments.menu.Edit Document Info")}>
                <EditIcon />
              </Tooltip>
            }
            label={t("assetDocuments.menu.Edit Asset Document")}
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title={t("assetDocuments.menu.Manage Files")}>
                <AttachFileIcon />
              </Tooltip>
            }
            label={t("assetDocuments.menu.Manage Files")}
            className="textPrimary"
            color="inherit"
            onClick={handleManageFilesClick(id)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label={t("assetDocuments.menu.Delete Document")}
            onClick={handleDeleteClick(id)}
            showInMenu
          />,
        ];
      },
    },
  ];

  const tableGrid = () => {
    let content;
    content = (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box>
          <b>{t("assetDocuments.Name")} </b>
          {selectedAssetName}
        </Box>
        {/** Confirm Dialog */}
        <Dialog
          open={openConfirmDeleteDialog}
          onClose={handleCloseConfirmDeleteDialog}
        >
          <DialogContent
            sx={{
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
            }}
          >
            <DialogContentText>
              {t("assetDocuments.dialog.Confirm Delete")}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
            }}
          >
            <Button
              onClick={handleCloseConfirmDeleteDialog}
              sx={{
                backgroundColor: theme.palette.background.alt,
                color: `${theme.palette.secondary[100]} !important`,
              }}
            >
              {t("assetDocuments.dialog.CANCEL")}
            </Button>
            <Button
              onClick={handleSubmitConfirmDeleteDialog}
              sx={{
                backgroundColor: theme.palette.background.alt,
                color: `${theme.palette.secondary[100]} !important`,
              }}
            >
              {t("assetDocuments.dialog.DELETE")}
            </Button>
          </DialogActions>
        </Dialog>
        {/** Snackbar */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={1000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            sx={{
              width: "100%",
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Dialog
          open={openConfirmDeleteFileDialog}
          onClose={handleCloseConfirmDeleteFileDialog}
        >
          <DialogContent
            sx={{
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
            }}
          >
            <DialogContentText>
              {t("assetDocuments.deleteFileDialog.Confirm Delete")}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
            }}
          >
            <Button
              onClick={handleCloseConfirmDeleteFileDialog}
              sx={{
                backgroundColor: theme.palette.background.alt,
                color: `${theme.palette.secondary[100]} !important`,
              }}
            >
              {t("assetDocuments.deleteFileDialog.CANCEL")}
            </Button>
            <Button
              onClick={handleSubmitConfirmDeleteFileDialog}
              sx={{
                backgroundColor: theme.palette.background.alt,
                color: `${theme.palette.secondary[100]} !important`,
              }}
            >
              {t("assetDocuments.deleteFileDialog.DELETE")}
            </Button>
          </DialogActions>
        </Dialog>
        <Modal
          open={openFileModal}
          onClose={handleCloseFileModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ overflow: "scroll" }}
        >
          <Box sx={fileModalStyle}>
            <Backdrop
              open={showPreview}
              onClick={handleClosePreview}
              sx={{ zIndex: 99, width: "100%" }}
            >
              <img src={previewUrl} width="200px" />
            </Backdrop>
            <Typography id="modal-modal-title" variant="h4" component="h2">
              {t("assetDocuments.modal.Manage Files")}
            </Typography>
            {renderListHeader()}
            <Box
              sx={{
                backgroundColor: theme.palette.primary[300],
                color: theme.palette.grey[1],
              }}
            >
              {renderFileList()}
            </Box>

            {isLoadingFiles && (
              <Box>
                <h4>Loading ...</h4>
              </Box>
            )}
            <Typography
              id="modal-modal-description"
              sx={{ mt: 2 }}
            ></Typography>

            <Divider
              variant="middle"
              sx={{
                backgroundColor: theme.palette.primary[200],
                color: theme.palette.grey[0],
              }}
            />
            <Box>
              <h4>{t("assetDocuments.modal.Add Files")}</h4>
            </Box>

            <DropboxAssetDocument
              assetId={selectedAssetId}
              customerId={selectedAssetCustomerId}
              assetDocumentId={selectedAssetDocumentId}
            />
          </Box>
        </Modal>
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <ThemeProvider theme={themeWithLocale}>
            <DataGrid
              loading={isLoadingAssetDocuments || !assetDocuments}
              rows={rows || []}
              columns={columns}
              getRowId={(row) => row.id}
              editMode="row"
              rowModesModel={rowModesModel}
              onRowModesModelChange={handleRowModesModelChange}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              onProcessRowUpdateError={handleProcessRowUpdateError}
              slots={{
                toolbar: EditToolbar,
              }}
              slotProps={{
                toolbar: { setRows, setRowModesModel, rows },
              }}
            />
          </ThemeProvider>
        </Box>
      </LocalizationProvider>
    );
    return content;
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title={t("assetDocuments.ASSET DOCUMENTS")}
        subtitle={t("assetDocuments.List of Asset Documents")}
      />
      <Box m="1.5rem 0rem">
        <Autocomplete
          filterOptions={filterOptions}
          loading={isLoading || isLoadingAssetDocuments || !lstAsset}
          onChange={(event, value) => handleSelectedAsset(value)} // prints the selected value
          id="select-asset"
          sx={{ width: 300 }}
          options={lstAsset}
          autoHighlight
          getOptionLabel={(option) => (option.id > 0 ? option.name : "")}
          value={optionDefaultValue || null}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
              key={option.id}
            >
              {option.name}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("assetDocuments.Choose an asset")}
              inputProps={{
                ...params.inputProps,
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Box>
      {!skip && tableGrid()}
    </Box>
  );
};

export default AssetDocuments;
