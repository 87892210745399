import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import FlexBetween from "components/FlexBetween";

import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";

import {
  ChevronLeft,
  ChevronRightOutlined,
  CorporateFare,
  ReceiptLong,
  PersonOutline,
  AssignmentInd,
  DomainAddOutlined,
  PlaylistAdd,
  DashboardOutlined,
  PostAdd,
  RequestQuote,
  ModelTraining,
  School,
  Assessment,
  FolderShared,
  Settings,
  Notifications,
  BackupTable,
} from "@mui/icons-material";

import { useTranslation } from "react-i18next";

const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile,
}) => {
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  // get user role
  //const { userInfo } = useSelector((state) => state.auth);
  const userData = JSON.parse(localStorage.getItem("userData"));

  // role - user navgation items
  const userNavItems = [
    {
      text: t("sidebar.DASHBOARD"),
      icon: null,
    },
    {
      text: t("sidebar.Overview"),
      icon: <DashboardOutlined />,
      path: "dashboard",
    },

    {
      text: t("sidebar.RENTAL"),
      icon: null,
    },
    {
      text: t("sidebar.My Assets"),
      icon: <CorporateFare />,
      path: "myassets",
    },
    {
      text: t("sidebar.My Asset Transactions"),
      icon: <ReceiptLong />,
      path: "myassettransactions",
    },
  ];

  // role - admin / superadmin navgation items
  const adminNavItems = [
    {
      text: t("sidebar.ADMINISTRATION"),
      icon: null,
    },
    {
      text: t("sidebar.Customers"),
      icon: <PersonOutline />,
      path: "customers",
    },
    {
      text: t("sidebar.Assets"),
      icon: <DomainAddOutlined />,
      path: "assets",
    },
    {
      text: t("sidebar.Asset Transactions"),
      icon: <PlaylistAdd />,
      path: "assettransactions",
    },
    {
      text: t("sidebar.INTERNAL DOCUMENT"),
      icon: null,
    },
    {
      text: t("sidebar.Account Documents"),
      icon: <PostAdd />,
      path: "accountdocuments",
    },
    {
      text: t("sidebar.Client Documents"),
      icon: <FolderShared />,
      path: "clientdocuments",
    },
    {
      text: t("sidebar.HUMAN RESOURCES"),
      icon: null,
    },
    {
      text: t("sidebar.Administrators"),
      icon: <AssignmentInd />,
      path: "administrators",
    },
    {
      text: t("sidebar.Training"),
      icon: <School />,
      path: "training",
    },
    {
      text: t("sidebar.TrainingStats"),
      icon: <Assessment />,
      path: "trainingstats",
    },
    ,
    {
      text: t("sidebar.OTHER"),
      icon: null,
    },

    {
      text: t("sidebar.Dashboard"),
      icon: <DashboardOutlined />,
      path: "admindashboard",
    },

    {
      text: t("sidebar.Quotations"),
      icon: <RequestQuote />,
      path: "quotations",
    },
    {
      text: t("sidebar.Workflows"),
      icon: <ModelTraining />,
      path: "workflows",
    },
    {
      text: t("sidebar.System Settings"),
      icon: <Settings />,
      path: "settings",
    },
    {
      text: t("sidebar.Notification Setting"),
      icon: <Notifications />,
      path: "notificationsetting",
    },
    {
      text: t("sidebar.Backup"),
      icon: <BackupTable />,
      path: "backup",
    },
  ];

  let navItems = [];

  if (userData) {
    const userRole = userData["role"];
    if (userRole === "customer") {
      navItems = userNavItems;
    } else if (userRole === "admin") {
      navItems = adminNavItems;
    } else {
      navigate("/404");
    }
  }

  useEffect(() => {
    setActive(pathname.substring(1), [pathname]);
  });

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSizing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth,
            },
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap="0.5rem">
                  <Typography variant="h5" fontWeight="bold">
                    <p style={{ whiteSpace: "pre-line" }}>
                      {t("sidebar.ENTERPRISE RESOURCE PLANNING PLATFORM")}
                    </p>
                  </Typography>
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            <List>
              {navItems.map(({ text, icon, path }) => {
                if (!icon) {
                  return (
                    <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                      {text}
                    </Typography>
                  );
                }
                const lcText = path;
                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        navigate(`/${lcText}`);
                        setActive(lcText);
                      }}
                      sx={{
                        backgroundColor:
                          active === lcText
                            ? theme.palette.secondary[300]
                            : "transparent",
                        color:
                          active === lcText
                            ? theme.palette.primary[600]
                            : theme.palette.secondary[100],
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          ml: "2rem",
                          color:
                            active === lcText
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[200],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Drawer>
      )}
    </Box>
  );
};

export default Sidebar;
