import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";
import { themeSettings } from "theme";
import Layout from "scenes/layout";
import Dashboard from "scenes/dashboard";
import Products from "scenes/products";
import Geography from "scenes/geography";
import Overview from "scenes/overview";
import Daily from "scenes/daily";
import Monthly from "scenes/monthly";
import Breakdown from "scenes/breakdown";

import Performance from "scenes/performance";
import Login from "scenes/login";
import ChangePassword from "scenes/changePassword";
import LoginLayout from "scenes/loginLayout";
import {
  setMode,
  setDarkMode,
  setLightMode,
} from "features/themeMode/themeModeSlice";
import Customers from "scenes/customers";
import Administrators from "scenes/administrators";
import Assets from "scenes/assets";
import AssetTransactions from "scenes/assetTransactions";
import MyAssets from "scenes/myAssets";
import MyAssetTransactions from "scenes/myAssetTransactions";
import AdminDashboard from "scenes/adminDashboard";
import AssetDocuments from "scenes/assetDocuments";
import AccountDocuments from "scenes/accountDocuments";
import ClientDocuments from "scenes/clientDocuments";
import Quotations from "scenes/quotations";
import ViewQuotation from "scenes/viewQuotation";
import Workflows from "scenes/workflows";
import ViewWorkflow from "scenes/viewWorkflow";
import Training from "scenes/training";
import TrainingStats from "scenes/trainingStats";
import Settings from "scenes/settings";
import NotificationSetting from "scenes/notificationSetting";
import Backup from "scenes/backup";

import { isExpired } from "utils/isExpired";

function App() {
  const dispatch = useDispatch();

  const mode = useSelector((state) => state.themeMode.mode);
  const { userInfo } = useSelector((state) => state.auth);

  // get local storage and see if there is preference saved
  const storedthemeMode = localStorage.getItem("themeMode");
  useEffect(() => {
    if (storedthemeMode) {
      // get the stored color mode and set it
      if (storedthemeMode === "light") {
        dispatch(setLightMode());
      } else {
        dispatch(setDarkMode());
      }
    } else {
      // store the current one
      localStorage.setItem("themeMode", mode);
    }
  }, []);

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  const CustomerAuthWrapper = () => {
    // no userToken ===> login page
    // userToken, but role != 'customer' ===> login page
    // useToken, but expired ===> login page
    if (
      localStorage.getItem("userToken") === null ||
      JSON.parse(localStorage.getItem("userData"))["role"] !== "customer" ||
      isExpired(localStorage.getItem("userToken"))
    ) {
      return <Navigate to="/login" replace />;
    }

    return <Outlet />;
  };

  const AdminAuthWrapper = () => {
    // no userToken ===> login page
    // userToken, but role != 'customer' ===> login page
    // useToken, but expired ===> login page
    if (
      localStorage.getItem("userToken") === null ||
      JSON.parse(localStorage.getItem("userData"))["role"] !== "admin" ||
      isExpired(localStorage.getItem("userToken"))
    ) {
      return <Navigate to="/login" replace />;
    }

    return <Outlet />;
  };

  const content = (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            <Route path="/" element={<Navigate replace to="/login" />} />
            {/* <Route path="*" element={<Navigate replace to="/404" />} /> */}
            <Route element={<LoginLayout />}>
              <Route path="/login" element={<Login />} />
              {/* <Route
                path="/404"
                element={
                  <div align="center">
                    <h1>404</h1>
                    <br />
                    Ooops, Page Not Found!
                    <br />
                    Sorry, The Requested Page Is Not Found.
                  </div>
                }
              /> */}
              {/* <Route
                path="/client/quotation/:path"
                element={<ViewQuotation />}
              /> */}
              <Route path="/qx/:path" element={<ViewQuotation />} />
              {/* <Route path="/client/workflow/:path" element={<ViewWorkflow />} /> */}
              <Route path="/wx/:path" element={<ViewWorkflow />} />
            </Route>
            <Route element={<CustomerAuthWrapper />}>
              <Route element={<Layout />}>
                {/* customer */}
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/changepassword" element={<ChangePassword />} />
                <Route path="/myassets" element={<MyAssets />} />
                <Route
                  path="/myassettransactions"
                  element={<MyAssetTransactions />}
                />
              </Route>
            </Route>
            <Route element={<AdminAuthWrapper />}>
              <Route element={<Layout />}>
                <Route path="/customers" element={<Customers />} />
                <Route path="/assets" element={<Assets />} />
                <Route
                  path="/assettransactions"
                  element={<AssetTransactions />}
                />
                <Route path="/assetdocuments" element={<AssetDocuments />} />
                <Route
                  path="/accountdocuments"
                  element={<AccountDocuments />}
                />
                <Route path="/clientdocuments" element={<ClientDocuments />} />
                <Route path="/administrators" element={<Administrators />} />
                <Route path="/admindashboard" element={<AdminDashboard />} />
                <Route path="/quotations" element={<Quotations />} />
                <Route path="/workflows" element={<Workflows />} />
                <Route path="/training" element={<Training />} />
                <Route path="/trainingstats" element={<TrainingStats />} />
                <Route path="/settings" element={<Settings />} />
                <Route
                  path="/notificationsetting"
                  element={<NotificationSetting />}
                />
                <Route path="/backup" element={<Backup />} />
              </Route>
            </Route>
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );

  return content;
}

export default App;
